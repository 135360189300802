// LoadingSequence.tsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './LoadingSequence.module.css';

const LoadingSequence: React.FC = () => {
    const [currentPhase, setCurrentPhase] = useState<string>('Searching...');
    const [documentNumber, setDocumentNumber] = useState<number>(1);
    const [processingNumber, setProcessingNumber] = useState<number>(1);
    const [isCountingDocuments, setIsCountingDocuments] = useState<boolean>(false);
    const [isCountingProcessing, setIsCountingProcessing] = useState<boolean>(false);
    const [dotCount, setDotCount] = useState<number>(1); // For cycling the ellipses

    const totalDocumentsRef = useRef<number>(
        Math.floor(Math.random() * (208 - 142 + 1)) + 142
    );
    const currentTimeRef = useRef<number>(0);

    // Cycle dotCount (1 to 3) every 500ms for animated ellipses
    useEffect(() => {
        const dotInterval = setInterval(() => {
            setDotCount(prev => (prev % 3) + 1);
        }, 500);
        return () => clearInterval(dotInterval);
    }, []);

    useEffect(() => {
        let docIntervalId: number | null = null;
        if (isCountingDocuments) {
            docIntervalId = window.setInterval(() => {
                setDocumentNumber(prev => {
                    if (prev < totalDocumentsRef.current) {
                        return prev + 1;
                    } else {
                        if (docIntervalId) clearInterval(docIntervalId);
                        return totalDocumentsRef.current;
                    }
                });
            }, 3);
        }

        return () => {
            if (docIntervalId) clearInterval(docIntervalId);
        };
    }, [isCountingDocuments]);

    useEffect(() => {
        let processIntervalId: number | null = null;
        if (isCountingProcessing) {
            processIntervalId = window.setInterval(() => {
                setProcessingNumber(prev => {
                    if (prev < totalDocumentsRef.current) {
                        return prev + 1;
                    } else {
                        if (processIntervalId) clearInterval(processIntervalId);
                        return totalDocumentsRef.current;
                    }
                });
            }, 3);
        }

        return () => {
            if (processIntervalId) clearInterval(processIntervalId);
        };
    }, [isCountingProcessing]);

    useEffect(() => {
        let mainIntervalId: number;

        const updateSequence = () => {
            const t = currentTimeRef.current;
    
            // Adjusted Timeline (total 40000ms):
            // 0-4000 ms: Searching...
            // 4000-5000 ms: Retrieving documents...
            // 5000-7000 ms: Retrieving document 1-150
            // 7000-8000 ms: Processing documents...
            // 8000-10000 ms: Processing document 1-150
            // 10000-11000 ms: Analyzing...
            // 11000-12000 ms: Synthesizing data...
            // 12000-15000 ms: Generating results...
            // 15000-30000 ms: Aren't you glad you don't have to do this manually?
            // >30000 ms: Almost there. Thank you for your patience.
    
            if (t < 4000) {
                setCurrentPhase('Searching...');
                setIsCountingDocuments(false);
            } else if (t < 5000) {
                setCurrentPhase('Retrieving documents...');
                setIsCountingDocuments(false);
            } else if (t < 7000) {
                // Retrieving counting phase
                if (t >= 5000 && t < 5100) {
                    setDocumentNumber(1);
                }
                setCurrentPhase(`Retrieving document ${documentNumber}`);
                setIsCountingDocuments(true);
            } else if (t < 8000) {
                setCurrentPhase('Processing documents...');
                setIsCountingDocuments(false);
            } else if (t < 10000) {
                // Processing counting phase
                if (t >= 8000 && t < 8100) {
                    setProcessingNumber(1);
                }
                setCurrentPhase(`Processing document ${processingNumber}`);
                setIsCountingProcessing(true);
                setIsCountingDocuments(false);
            } else if (t < 16000) {
                setCurrentPhase('Analyzing...');
                setIsCountingDocuments(false);
            } else if (t < 22000) {
                setCurrentPhase('Synthesizing data...');
                setIsCountingDocuments(false);
            } else if (t < 45000) {
                setCurrentPhase('Generating results...');
                setIsCountingDocuments(false);
            } else if (t < 60000) {  // Extended from 25000 to 30000
                setCurrentPhase("Aren't you glad you don't have to do this manually?");
                setIsCountingDocuments(false);
            } else {
                setCurrentPhase('Almost there. Thank you for your patience...');
                setIsCountingDocuments(false);
            }
    
            currentTimeRef.current += 25;
            mainIntervalId = window.setTimeout(updateSequence, 25);
        };    

        updateSequence();

        return () => {
            clearTimeout(mainIntervalId);
        };
    }, [documentNumber, processingNumber]);

    let displayedPhase = currentPhase;
    if (displayedPhase.includes('...')) {
        const dots = '.'.repeat(dotCount);
        displayedPhase = displayedPhase.replace('...', dots);
    }

    return (
        <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
                {displayedPhase}
            </div>
        </div>
    );
};

export default LoadingSequence;
