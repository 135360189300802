import styles from "./KnowledgeDomainButton.module.css";

import { useContext } from "react";

import { AppStateContext } from "../../state/AppProvider";

import IconKnowledgeDomainDefault from "../common/IconKnowledgeDomainDefault";
import IconKnowledgeDomainButtonChevronSort from "../common/IconKnowledgeDomainButtonChevronSort";

interface KnowledgeDomainButtonProps {
    openModal: () => void;
    isDocked?: boolean;
};

const KnowledgeDomainButton: React.FunctionComponent<KnowledgeDomainButtonProps> = ({ openModal, isDocked = false }) => {
    const appStateContext = useContext(AppStateContext);

    return (
        <div className={styles["knowledge-domain-button"]} onClick={openModal}>
            <div className={styles["knowledge-domain-button__icon-container"]}>
                {
                    appStateContext?.state.currentKnowledgeDomain?.iconUrl?.length ?? 0 > 0 ?
                        <img className={styles["knowledge-domain-button__icon"]} src={appStateContext?.state.currentKnowledgeDomain?.iconUrl} />
                        : <IconKnowledgeDomainDefault />
                }
            </div>
            <div className={`${styles["knowledge-domain-button__label"]} ${isDocked ? styles["knowledge-domain-button__label--docked"] : ''}`}>{appStateContext?.state.currentKnowledgeDomain?.label}</div>
            {
                !appStateContext?.state.isSingleKnowledgeDomain
                && <IconKnowledgeDomainButtonChevronSort className={`${styles["knowledge-domain-button__chevron"]} ${isDocked ? styles["knowledge-domain-button__chevron--docked"] : ''}`} />
            }
        </div>
    );
};

export default KnowledgeDomainButton;