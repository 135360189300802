const IconFileUploaderIllustration: React.FunctionComponent = () => {
    return (
        <svg width="63" height="48" viewBox="0 0 63 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.05" d="M41.8777 29.0517C41.444 29.5673 40.9757 29.5587 40.6966 29.486L40.707 29.219C40.707 29.219 41.8604 28.6918 41.831 28.8026C41.8201 28.8441 41.842 28.9439 41.8777 29.0517Z" fill="#FF5630" />
            <path opacity="0.05" d="M45.0389 29.6353C44.3024 30.0609 43.8704 29.562 43.7701 29.4207C43.8318 29.2587 43.9246 28.9957 43.9056 28.9207C43.8779 28.8094 45.0296 29.3365 45.0296 29.3365L45.0389 29.6353Z" fill="#FF5630" />
            <path opacity="0.05" d="M44.2502 25.3891C44.2502 25.3891 44.9509 24.9854 45.1037 25.1671C45.2565 25.3488 44.2502 25.3891 44.2502 25.3891Z" fill="#FF5630" />
            <path opacity="0.05" d="M44.673 25.6114C44.673 25.6114 44.5577 25.6691 44.812 25.7429C45.0664 25.8167 44.673 25.6114 44.673 25.6114Z" fill="#FF5630" />
            <path opacity="0.05" d="M40.4359 24.5011C40.4123 24.508 41.019 24.9105 41.5668 24.5772C41.5645 24.5772 40.5726 24.4595 40.4359 24.5011Z" fill="#FF5630" />
            <path opacity="0.05" d="M41.5369 25.2705C41.5369 25.2705 41.3708 25.5969 41.0305 25.6454C40.6903 25.6938 41.5369 25.2705 41.5369 25.2705Z" fill="#FF5630" />
            <path opacity="0.05" d="M43.7643 25.9785C43.7643 25.9785 44.5625 27.2473 44.4097 27.5466C44.2568 27.8459 43.7643 25.9785 43.7643 25.9785Z" fill="#FF5630" />
            <path opacity="0.05" d="M42.1896 31.0243C42.1896 31.0952 42.1896 31.1616 42.1827 31.2164C42.1619 31.473 41.5304 31.3761 41.5304 31.3761C40.9537 31.4563 40.7461 31.1795 40.6774 30.9147C40.6559 30.8288 40.6452 30.7404 40.6457 30.6518C40.6453 30.5989 40.6488 30.5461 40.6561 30.4937V30.4412C40.7804 30.4038 40.9152 30.4313 41.0148 30.5145C41.2714 30.7227 41.6117 30.5629 41.6117 30.5629C41.6117 30.5629 41.8614 30.5837 41.9577 30.6667C42.012 30.7135 42.1013 30.601 42.1694 30.4937C42.1734 30.5629 42.1798 30.6881 42.1838 30.8219C42.1884 30.8905 42.1896 30.9597 42.1896 31.0243Z" fill="#FF5630" />
            <path opacity="0.05" d="M41.1769 31.0096C41.1769 31.0096 41.0038 31.1791 40.6774 30.9173C40.6558 30.8313 40.6452 30.7429 40.6457 30.6543C40.8124 30.7812 41.1215 31.0096 41.1769 31.0096Z" fill="#FF5630" />
            <path opacity="0.05" d="M42.1892 31.0241C42.1607 31.0461 42.1245 31.0553 42.089 31.0496C42.0535 31.0439 42.0219 31.0239 42.0017 30.9941C42.069 30.9429 42.1309 30.8849 42.1863 30.8211C42.188 30.8903 42.1892 30.9595 42.1892 31.0241Z" fill="#FF5630" />
            <path opacity="0.05" d="M43.5427 31.1428C43.5427 31.2143 43.5427 31.2807 43.549 31.3349C43.5698 31.5921 44.2013 31.4946 44.2013 31.4946C44.778 31.5754 44.9856 31.2985 45.0543 31.0332C45.0883 30.8956 45.0955 30.7526 45.0756 30.6122V30.5598C44.9511 30.5229 44.8164 30.5504 44.7163 30.633C44.4597 30.8412 44.12 30.6814 44.12 30.6814C44.12 30.6814 43.8703 30.7028 43.7739 30.7858C43.7197 30.8325 43.6303 30.7201 43.5629 30.6128C43.5588 30.682 43.5519 30.8072 43.5479 30.941C43.5433 31.0084 43.5421 31.0777 43.5427 31.1428Z" fill="#FF5630" />
            <path opacity="0.05" d="M44.5557 31.127C44.5557 31.127 44.7287 31.2965 45.0546 31.0347C45.0763 30.9489 45.0872 30.8608 45.0869 30.7723C44.9196 30.8986 44.6105 31.127 44.5557 31.127Z" fill="#FF5630" />
            <path opacity="0.05" d="M43.5427 31.1434C43.5711 31.1654 43.6072 31.1746 43.6426 31.1689C43.678 31.1632 43.7095 31.1431 43.7295 31.1134C43.6622 31.0623 43.6004 31.0043 43.545 30.9404C43.5433 31.0091 43.5421 31.0783 43.5427 31.1434Z" fill="#FF5630" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7564 43.185C15.6167 43.0168 15.5526 42.7983 15.5794 42.5812L18.125 21.7517L18.3361 20.0216C18.385 19.6235 18.7216 19.3234 19.1228 19.3203L26.5792 19.2707C26.7937 19.2694 26.9986 19.1817 27.1477 19.0275C27.2968 18.8733 27.3775 18.6656 27.3716 18.4512L27.3687 18.3722C27.3614 18.1559 27.4421 17.946 27.5925 17.7904C27.7429 17.6348 27.9499 17.5469 28.1663 17.5469H36.0017C36.4415 17.5469 36.7983 17.9029 36.7993 18.3428C36.7992 18.3786 36.7969 18.4144 36.7923 18.45C36.7623 18.6745 36.8291 18.9012 36.9761 19.0735C37.1231 19.2458 37.3364 19.3475 37.5628 19.3532L45.0879 19.5452C45.3194 19.5509 45.537 19.657 45.6841 19.836C45.8311 20.0149 45.8931 20.2489 45.8538 20.4772L42.0918 42.4503L42.0209 42.8655C42.0034 42.9716 41.9641 43.0729 41.9055 43.1631L15.7564 43.185Z" fill="#004B50" />
            <rect x="38.465" y="13.1348" width="4.40615" height="14.955" transform="rotate(34.64 38.465 13.1348)" fill="white" />
            <rect x="38.7262" y="13.7177" width="3.11066" height="3.11066" transform="rotate(34.804 38.7262 13.7177)" fill="#FFAB00" />
            <rect opacity="0.5" x="36.7256" y="16.7959" width="3.11487" height="3.11487" transform="rotate(34.64 36.7256 16.7959)" fill="#FFAB00" />
            <rect opacity="0.3" x="34.5876" y="19.8896" width="3.11487" height="3.11487" transform="rotate(34.64 34.5876 19.8896)" fill="#FFAB00" />
            <rect x="33.3436" y="12.3641" width="4.40615" height="14.8984" transform="rotate(16.29 33.3436 12.3641)" fill="white" />
            <rect x="33.8986" y="12.7773" width="3.11487" height="3.11487" transform="rotate(16.29 33.8986 12.7773)" fill="#FF5630" />
            <rect opacity="0.5" x="32.8439" y="16.3867" width="3.11487" height="3.11487" transform="rotate(16.29 32.8439 16.3867)" fill="#FF5630" />
            <rect opacity="0.3" x="31.7887" y="19.9951" width="3.11487" height="3.11487" transform="rotate(16.29 31.7887 19.9951)" fill="#FF5630" />
            <rect x="30.3474" y="12.5899" width="4.40615" height="14.8984" transform="rotate(4.6 30.3474 12.5899)" fill="white" />
            <rect x="30.9728" y="12.8818" width="3.11487" height="3.11487" transform="rotate(4.6 30.9728 12.8818)" fill="#00B8D9" />
            <rect opacity="0.5" x="30.6706" y="16.6291" width="3.11487" height="3.11487" transform="rotate(4.6 30.6706 16.6291)" fill="#00B8D9" />
            <rect opacity="0.3" x="30.3691" y="20.378" width="3.11487" height="3.11487" transform="rotate(4.6 30.3691 20.378)" fill="#00B8D9" />
            <rect x="28.3716" y="13.1867" width="4.40615" height="14.8984" transform="rotate(-2.61 28.3716 13.1867)" fill="white" />
            <rect x="29.0275" y="13.3965" width="3.11487" height="3.11487" transform="rotate(-2.61 29.0275 13.3965)" fill="#00A76F" />
            <rect opacity="0.5" x="29.321" y="17.0342" width="3.11876" height="3.11876" transform="rotate(-2.86241 29.321 17.0342)" fill="#00A76F" />
            <rect opacity="0.3" x="29.3702" y="20.9103" width="3.11487" height="3.11487" transform="rotate(-2.61 29.3702 20.9103)" fill="#00A76F" />
            <path d="M30.2406 32.82L26.3258 34.3344L20.6041 36.5462C20.3879 36.1425 20.0695 35.5969 19.6756 34.9458C18.3203 32.7 16.078 29.2068 14.0814 26.1369C11.8322 22.6766 9.89616 19.7566 9.89616 19.7566L11.2976 19.3529L22.7455 16.0293L30.2406 32.82Z" fill="#F4F6F8" />
            <path opacity="0.1" d="M30.2409 32.82L26.3261 34.3344C24.1392 34.79 21.9075 34.9951 19.6742 34.9458C18.3189 32.7 16.0766 29.2068 14.08 26.1369L11.2985 19.3506L22.7458 16.0293L30.2409 32.82Z" fill="black" />
            <path d="M33.153 31.8782C33.153 31.8782 27.0714 35.4758 17.9915 34.6621L10.7531 17.0144L14.2221 16.4181L26.2144 14.3586L33.0197 31.5351L33.153 31.8782Z" fill="#00A76F" />
            <path opacity="0.1" d="M33.0177 31.5368C31.5857 31.6072 29.9288 31.6003 28.262 31.4013C26.4581 31.1891 24.6437 30.7519 23.0883 29.9503C21.7878 29.2807 20.6758 28.5523 19.7064 27.6786C17.1844 25.4103 15.6238 22.1657 14.2223 16.4198L26.2147 14.3604L33.0177 31.5368Z" fill="#FF5630" />
            <path d="M37.479 30.5506C37.479 30.5506 32.9961 31.4283 28.4331 30.8879C26.6291 30.6757 24.8148 30.2386 23.2593 29.4369C21.9588 28.7673 20.8475 28.0389 19.8775 27.1652C16.7562 24.3572 15.108 20.0537 13.4084 11.363C13.4084 11.363 20.8608 13.1618 28.2301 9.22168C28.2278 9.22456 30.7117 24.7257 37.479 30.5506Z" fill="white" />
            <g opacity="0.3">
                <path d="M17.2634 14.5531C17.2634 14.5531 23.3235 14.2106 25.0156 12.9043L25.2723 13.729C25.2723 13.729 21.2681 15.4955 17.2634 15.5168V14.5531Z" fill="#00A76F" />
                <path d="M17.2634 18.6248C17.2634 18.6248 23.1309 18.3036 27.5642 16.1622L27.7141 16.5048C27.7141 16.5048 24.1961 18.5585 17.2634 19.096V18.6248Z" fill="#00A76F" />
                <path d="M17.7358 20.2114C17.7358 20.2114 23.6034 19.8901 28.036 17.7488L28.186 18.0913C28.186 18.0913 24.668 20.1445 17.7358 20.6826V20.2114Z" fill="#00A76F" />
                <path d="M18.2075 21.8002C18.2075 21.8002 24.0751 21.479 28.5083 19.3376L28.6577 19.6802C28.6577 19.6802 25.1397 21.7339 18.2075 22.2714V21.8002Z" fill="#00A76F" />
                <path d="M18.6798 23.3891C18.6798 23.3891 24.5473 23.0679 28.98 20.9265L29.13 21.2691C29.13 21.2691 25.612 23.3228 18.6798 23.8603V23.3891Z" fill="#00A76F" />
                <path d="M19.1515 24.9785C19.1515 24.9785 25.019 24.6573 29.4523 22.5159L29.6017 22.8585C29.6017 22.8585 26.0837 24.9116 19.1515 25.4497V24.9785Z" fill="#00A76F" />
            </g>
            <path d="M60.4084 44.7062C60.4084 44.7062 57.97 44.5592 58.2572 46.268C58.2572 46.268 58.1995 46.5696 58.474 46.7069C58.474 46.7069 58.4781 46.58 58.7238 46.6233C58.8114 46.638 58.9004 46.6423 58.989 46.636C59.1086 46.6279 59.2223 46.5809 59.3126 46.5022C59.3126 46.5022 59.9983 46.219 60.2648 45.099C60.2648 45.099 60.462 44.8545 60.4539 44.7916L60.0427 44.9646C60.0427 44.9646 60.1834 45.2616 60.0727 45.5079C60.0727 45.5079 60.0594 44.9756 59.9804 44.9888C59.9643 44.9888 59.7665 45.0915 59.7665 45.0915C59.7665 45.0915 60.0081 45.6105 59.8241 45.9843C59.8241 45.9843 59.8933 45.347 59.6892 45.129L59.4008 45.298C59.4008 45.298 59.6834 45.832 59.4919 46.268C59.4919 46.268 59.541 45.5996 59.3397 45.3389L59.0773 45.5436C59.0773 45.5436 59.3432 46.0702 59.1811 46.4318C59.1811 46.4318 59.1598 45.6532 59.0202 45.5944C59.0202 45.5944 58.7895 45.7968 58.756 45.8828C58.756 45.8828 58.9377 46.2645 58.8247 46.4664C58.8247 46.4664 58.7555 45.9473 58.699 45.9473C58.699 45.9473 58.4683 46.2934 58.4463 46.5241C58.4463 46.5241 58.4562 46.1734 58.6436 45.9122C58.5115 45.9374 58.3897 46.0005 58.2929 46.0938C58.2929 46.0938 58.3287 45.8505 58.7001 45.8297C58.7001 45.8297 58.8893 45.569 58.9394 45.5529C58.9394 45.5529 58.5703 45.5223 58.3466 45.6215C58.3466 45.6215 58.5438 45.3908 59.0075 45.4964L59.2664 45.2853C59.2664 45.2853 58.7803 45.2184 58.5744 45.2922C58.5744 45.2922 58.8114 45.0892 59.3362 45.2345L59.6182 45.0661C59.6182 45.0661 59.2042 44.9767 58.9573 45.0084C58.9573 45.0084 59.2174 44.8677 59.7013 45.02L59.9026 44.9294C59.9026 44.9294 59.5992 44.8718 59.5104 44.8602C59.4216 44.8487 59.4164 44.8268 59.4164 44.8268C59.6087 44.7943 59.8063 44.8163 59.9868 44.8902C59.9868 44.8902 60.4153 44.7333 60.4084 44.7062Z" fill="#00A76F" />
            <path d="M61.544 39.0653C61.544 39.0653 60.1697 38.9823 60.3329 39.946C60.3156 40.0461 60.3647 40.146 60.4546 40.1934C60.4546 40.1934 60.4575 40.1219 60.5959 40.1461C60.6452 40.1542 60.6953 40.1566 60.7452 40.153C60.8126 40.1487 60.8766 40.1223 60.9275 40.0781C60.9275 40.0781 61.3139 39.9183 61.4644 39.2868C61.4644 39.2868 61.5757 39.149 61.5711 39.1138L61.3404 39.2124C61.3404 39.2124 61.4194 39.3802 61.3571 39.5192C61.3571 39.5192 61.3496 39.2187 61.3052 39.2257C61.296 39.2257 61.1847 39.2833 61.1847 39.2833C61.1847 39.2833 61.3208 39.5717 61.2181 39.7868C61.2181 39.7868 61.2574 39.4275 61.142 39.3041L60.9788 39.3998C60.9788 39.3998 61.138 39.7009 61.0301 39.9466C61.0301 39.9466 61.0578 39.5694 60.9442 39.4229L60.7966 39.5382C60.7966 39.5382 60.9459 39.8353 60.8542 40.0388C60.8542 40.0388 60.8421 39.6 60.7637 39.5671C60.7077 39.6146 60.6575 39.6686 60.6143 39.728C60.6143 39.728 60.717 39.9437 60.6535 40.0573C60.6535 40.0573 60.6143 39.7649 60.5826 39.7637C60.5826 39.7637 60.4534 39.9569 60.4401 40.0896C60.4464 39.9665 60.4848 39.8472 60.5515 39.7436C60.4769 39.7576 60.4081 39.7933 60.3536 39.8462C60.3536 39.8462 60.3738 39.709 60.5843 39.6974C60.5843 39.6974 60.691 39.5504 60.7193 39.5411C60.7193 39.5411 60.5111 39.5238 60.3848 39.5798C60.3848 39.5798 60.4961 39.4506 60.7573 39.5094L60.9038 39.39C60.9038 39.39 60.6293 39.3525 60.5134 39.3941C60.5134 39.3941 60.6472 39.2787 60.9431 39.3629L61.1022 39.2678C60.9798 39.241 60.8543 39.2303 60.7291 39.236C60.7291 39.236 60.8762 39.1565 61.1484 39.2424L61.2637 39.1916C61.2637 39.1916 61.0907 39.1576 61.0422 39.1524C60.9938 39.1472 60.9892 39.1334 60.9892 39.1334C61.0977 39.1153 61.2091 39.1277 61.311 39.1691C61.311 39.1691 61.5486 39.0803 61.544 39.0653Z" fill="#00A76F" />
            <path d="M53.4306 39.9016C53.4306 39.9016 51.7708 39.8012 51.9669 40.9644C51.946 41.0854 52.0054 41.206 52.114 41.2632C52.114 41.2632 52.114 41.1767 52.287 41.2055C52.3466 41.2156 52.4072 41.2185 52.4675 41.2142C52.5489 41.2087 52.6263 41.1767 52.6878 41.123C52.6878 41.123 53.1544 40.9304 53.336 40.168C53.336 40.168 53.4704 40.0019 53.4647 39.9586L53.1815 40.0786C53.1815 40.0786 53.2772 40.2805 53.2017 40.4483C53.2017 40.4483 53.1924 40.0861 53.1388 40.0942C53.1279 40.0942 52.9935 40.1645 52.9935 40.1645C52.9935 40.1645 53.1578 40.5163 53.0338 40.7724C53.0338 40.7724 53.0811 40.3387 52.9421 40.1899L52.7449 40.3053C52.7449 40.3053 52.937 40.6692 52.8066 40.9656C52.8066 40.9656 52.8401 40.5106 52.7034 40.3312L52.5246 40.4708C52.5246 40.4708 52.7057 40.8289 52.595 41.0752C52.595 41.0752 52.5811 40.5452 52.486 40.5054C52.486 40.5054 52.3297 40.6432 52.306 40.6997C52.306 40.6997 52.4294 40.9598 52.3527 41.0971C52.3527 41.0971 52.3054 40.7441 52.2668 40.7424C52.2668 40.7424 52.1117 40.9766 52.0938 41.1363C52.1016 40.9882 52.1479 40.8446 52.2282 40.7199C52.1383 40.737 52.0555 40.7802 51.99 40.8439C51.99 40.8439 52.0142 40.6784 52.2668 40.664C52.2668 40.664 52.3954 40.4863 52.43 40.476C52.43 40.476 52.1786 40.4546 52.0263 40.5221C52.0263 40.5221 52.1601 40.3664 52.4756 40.4373L52.6521 40.2931C52.6521 40.2931 52.3216 40.2482 52.1809 40.2978C52.1809 40.2978 52.3424 40.1599 52.6999 40.2608L52.892 40.1455C52.892 40.1455 52.61 40.0849 52.4416 40.1069C52.4416 40.1069 52.6192 40.0111 52.9479 40.1149L53.0852 40.0532C53.0852 40.0532 52.8787 40.0129 52.8182 40.0065C52.7576 40.0002 52.7547 39.9834 52.7547 39.9834C52.8855 39.9613 53.02 39.976 53.1428 40.0261C53.1428 40.0261 53.4358 39.9194 53.4306 39.9016Z" fill="#00A76F" />
            <rect x="39.0085" y="13.3926" width="9.8429" height="17.6142" rx="2.19316" transform="rotate(-71.99 39.0085 13.3926)" fill="white" />
            <g opacity="0.24">
                <path d="M45.7326 9.24414C45.7326 10.243 44.9229 11.0527 43.924 11.0527C42.9251 11.0527 42.1154 10.243 42.1154 9.24414C42.1154 8.24528 42.9251 7.43555 43.924 7.43555C44.9229 7.43555 45.7326 8.24528 45.7326 9.24414Z" fill="#00A76F" />
                <path d="M48.2513 8.91855L48.4045 8.44742L55.8981 10.8837L55.7449 11.3548L48.2513 8.91855Z" fill="#00A76F" />
                <path d="M47.8607 10.1197L48.0139 9.64858L51.8312 10.8896L51.678 11.3608L47.8607 10.1197Z" fill="#00A76F" />
                <path d="M47.4694 11.3217L47.6226 10.8505L53.7967 12.8578L53.6435 13.3289L47.4694 11.3217Z" fill="#00A76F" />
                <path d="M47.0791 12.5231L47.2322 12.052L54.7259 14.4883L54.5727 14.9594L47.0791 12.5231Z" fill="#00A76F" />
            </g>
            <rect opacity="0.5" x="51.7922" y="16.46" width="0.957933" height="2.18058" transform="rotate(-71.99 51.7922 16.46)" fill="#00A76F" />
            <path d="M39.8085 31.9875C38.518 31.1579 38.1444 29.4392 38.974 28.1487L41.2167 24.66C42.0463 23.3695 43.7649 22.9959 45.0554 23.8255L53.0882 28.9895C54.3787 29.8191 54.7523 31.5377 53.9228 32.8282L51.68 36.3169C50.8504 37.6074 49.1318 37.981 47.8413 37.1514L39.8085 31.9875Z" fill="white" />
            <path d="M45.3683 33.0986L48.6015 28.0098L49.1014 28.3274L45.8683 33.4162L45.3683 33.0986Z" fill="#F4F6F8" />
            <path d="M46.4363 33.7765L49.6694 28.6877L50.1694 29.0054L46.9362 34.0942L46.4363 33.7765Z" fill="#F4F6F8" />
            <path d="M39.3545 29.9162L40.8267 27.5991L41.3719 27.9455L39.8997 30.2625L39.3545 29.9162Z" fill="#C4CDD5" />
            <path d="M41.097 30.3855L44.3302 25.2968L44.8301 25.6144L41.597 30.7032L41.097 30.3855Z" fill="#C4CDD5" />
            <path d="M42.165 31.0634L45.3981 25.9746L45.8981 26.2923L42.6649 31.3811L42.165 31.0634Z" fill="#C4CDD5" />
            <path d="M43.2327 31.7425L46.4659 26.6537L46.9658 26.9713L43.7326 32.0601L43.2327 31.7425Z" fill="#C4CDD5" />
            <path d="M44.3375 32.7262L47.5932 27.6618L48.0908 27.9817L44.8351 33.0461L44.3375 32.7262Z" fill="#C4CDD5" />
            <path d="M50.9195 30.8863L51.7568 29.5686L53.0745 30.4058L52.2373 31.7235L50.9195 30.8863Z" fill="#C4CDD5" />
            <path d="M37.3003 21.8311L28.4719 21.9262C28.1414 21.9297 27.8752 22.1984 27.8749 22.5289V23.2585C27.8747 23.5856 27.6138 23.853 27.2867 23.8611L20.9162 24.014C20.6384 24.0209 20.3918 23.837 20.3193 23.5687L20.02 22.4689C19.9482 22.2036 19.706 22.0205 19.4312 22.0237L10.9217 22.1154C10.7452 22.1171 10.5784 22.1961 10.4652 22.3315C10.352 22.4669 10.3038 22.645 10.3334 22.819L13.7568 42.9673C13.8059 43.2565 14.0558 43.4685 14.3491 43.4697L41.2324 43.585C41.4125 43.5856 41.5835 43.5057 41.6986 43.3671C41.8137 43.2285 41.8607 43.0457 41.827 42.8687L37.8984 22.3207C37.8437 22.0343 37.5919 21.8281 37.3003 21.8311Z" fill="url(#paint0_linear_5087_82127)" />
            <g opacity="0.2">
                <path d="M60.4544 46.8555C60.4544 47.0166 59.6819 47.1473 58.7289 47.1473C57.7759 47.1473 57.0033 47.0166 57.0033 46.8555C57.0033 46.6943 57.7759 46.5636 58.7289 46.5636C59.6819 46.5636 60.4544 46.6943 60.4544 46.8555Z" fill="#00A76F" />
                <path d="M61.5711 40.2768C61.5711 40.3676 61.1355 40.4412 60.5981 40.4412C60.0608 40.4412 59.6252 40.3676 59.6252 40.2768C59.6252 40.186 60.0608 40.1124 60.5981 40.1124C61.1355 40.1124 61.5711 40.186 61.5711 40.2768Z" fill="#00A76F" />
                <path d="M53.4624 41.3645C53.4624 41.4741 52.9365 41.5629 52.2877 41.5629C51.6388 41.5629 51.1129 41.4741 51.1129 41.3645C51.1129 41.255 51.6388 41.1661 52.2877 41.1661C52.9365 41.1661 53.4624 41.255 53.4624 41.3645Z" fill="#00A76F" />
                <path d="M9.36112 39.1735C9.36112 39.2446 8.94256 39.3021 8.42625 39.3021C7.90994 39.3021 7.49139 39.2446 7.49139 39.1735C7.49139 39.1025 7.90994 39.0449 8.42625 39.0449C8.94256 39.0449 9.36112 39.1025 9.36112 39.1735Z" fill="#00A76F" />
                <path d="M5.7802 39.5363C5.7802 39.6073 5.36165 39.6649 4.84534 39.6649C4.32902 39.6649 3.91047 39.6073 3.91047 39.5363C3.91047 39.4653 4.32902 39.4077 4.84534 39.4077C5.36165 39.4077 5.7802 39.4653 5.7802 39.5363Z" fill="#00A76F" />
                <path d="M6.94295 40.5704C6.94295 40.6207 6.64549 40.6615 6.27856 40.6615C5.91163 40.6615 5.61418 40.6207 5.61418 40.5704C5.61418 40.52 5.91163 40.4792 6.27856 40.4792C6.64549 40.4792 6.94295 40.52 6.94295 40.5704Z" fill="#00A76F" />
                <path d="M3.22639 40.4792C3.22639 40.5296 2.92894 40.5704 2.56201 40.5704C2.19508 40.5704 1.89763 40.5296 1.89763 40.4792C1.89763 40.4289 2.19508 40.3881 2.56201 40.3881C2.92894 40.3881 3.22639 40.4289 3.22639 40.4792Z" fill="#00A76F" />
            </g>
            <path d="M2.19596 40.2722C2.15418 40.2381 2.1238 40.192 2.10888 40.1401C2.09417 40.0865 2.11988 40.0299 2.17001 40.0058C2.22768 39.9844 2.28535 40.0231 2.33149 40.0634C2.37763 40.1038 2.43011 40.1465 2.48778 40.1367C2.42552 40.0803 2.39749 39.9953 2.41396 39.9129C2.41633 39.8961 2.42332 39.8804 2.43415 39.8674C2.46529 39.8339 2.52181 39.8483 2.55929 39.8748C2.67464 39.9579 2.71097 40.1188 2.71155 40.2641C2.71732 40.2108 2.71732 40.1571 2.71155 40.1038C2.70845 40.0493 2.73266 39.9967 2.77614 39.9637C2.80422 39.9483 2.83587 39.9408 2.86784 39.9417C2.92143 39.9341 2.97576 39.9496 3.01721 39.9844C3.0576 40.0394 3.05522 40.1149 3.01144 40.1672C2.96726 40.2168 2.915 40.2585 2.85688 40.2907C2.81277 40.3172 2.77514 40.3533 2.74673 40.3962C2.74324 40.402 2.74053 40.4082 2.73866 40.4147H2.403C2.32787 40.3768 2.25822 40.3288 2.19596 40.2722Z" fill="#00A76F" />
            <path d="M8.04674 39.0138C8.00486 38.98 7.97444 38.9341 7.95965 38.8823C7.94486 38.8288 7.97031 38.7723 8.02021 38.7479C8.07788 38.7266 8.13555 38.7652 8.18169 38.8056C8.22783 38.846 8.27973 38.8898 8.33914 38.8812C8.27686 38.825 8.24862 38.7403 8.26474 38.658C8.2673 38.6411 8.27449 38.6254 8.2855 38.6124C8.31664 38.579 8.37316 38.5934 8.41065 38.6193C8.52888 38.703 8.56233 38.8639 8.5629 39.0092C8.56868 38.9557 8.56868 38.9018 8.5629 38.8483C8.55768 38.7944 8.57923 38.7414 8.62058 38.7064C8.64877 38.6915 8.68037 38.6841 8.71227 38.6851C8.76577 38.6775 8.81996 38.6928 8.86164 38.7272C8.90218 38.7824 8.89956 38.8583 8.8553 38.9106C8.81144 38.9603 8.75935 39.002 8.70132 39.034C8.65719 39.0603 8.61955 39.0961 8.59116 39.139C8.58778 39.145 8.58508 39.1514 8.58309 39.158H8.25493C8.17924 39.1197 8.10918 39.0712 8.04674 39.0138Z" fill="#00A76F" />
            <path d="M6.09695 40.2722C6.05519 40.2379 6.02466 40.1919 6.00929 40.1401C5.99458 40.0865 6.02029 40.0299 6.07042 40.0058C6.12809 39.9844 6.18576 40.0231 6.2319 40.0634C6.27804 40.1038 6.32937 40.1476 6.38935 40.1396C6.32717 40.0831 6.29897 39.9982 6.31495 39.9158C6.31751 39.899 6.3247 39.8832 6.33571 39.8702C6.36685 39.8368 6.42337 39.8512 6.46086 39.8777C6.57909 39.9608 6.61196 40.1217 6.61311 40.267C6.61888 40.2137 6.61888 40.16 6.61311 40.1067C6.60858 40.0523 6.63125 39.9992 6.67367 39.9648C6.70171 39.9494 6.73339 39.9418 6.76537 39.9429C6.81896 39.9353 6.87325 39.9508 6.91474 39.9856C6.95518 40.0406 6.95256 40.1163 6.90839 40.1684C6.86442 40.2179 6.81235 40.2597 6.75441 40.2918C6.7103 40.3184 6.67267 40.3544 6.64426 40.3973C6.64077 40.4031 6.63806 40.4093 6.63618 40.4158H6.30168C6.22735 40.3773 6.1585 40.329 6.09695 40.2722Z" fill="#00A76F" />
            <g opacity="0.08">
                <path d="M2.89784 6.0605C2.89784 6.74945 2.33934 7.30795 1.65039 7.30795C0.961448 7.30795 0.402946 6.74945 0.402946 6.0605C0.402946 5.37156 0.961448 4.81306 1.65039 4.81306C2.33934 4.81306 2.89784 5.37156 2.89784 6.0605Z" fill="#00A76F" />
                <path d="M62.882 16.0291C62.882 16.7181 62.3235 17.2766 61.6346 17.2766C60.9456 17.2766 60.3871 16.7181 60.3871 16.0291C60.3871 15.3402 60.9456 14.7817 61.6346 14.7817C62.3235 14.7817 62.882 15.3402 62.882 16.0291Z" fill="#00A76F" />
                <path d="M40.458 2.09901C40.458 2.78796 39.8995 3.34646 39.2106 3.34646C38.5216 3.34646 37.9631 2.78796 37.9631 2.09901C37.9631 1.41006 38.5216 0.851562 39.2106 0.851562C39.8995 0.851562 40.458 1.41006 40.458 2.09901Z" fill="#00A76F" />
                <path d="M6.23589 12.9425C6.23589 14.0971 5.29989 15.0331 4.14528 15.0331C2.99066 15.0331 2.05466 14.0971 2.05466 12.9425C2.05466 11.7879 2.99066 10.8519 4.14528 10.8519C5.29989 10.8519 6.23589 11.7879 6.23589 12.9425Z" fill="#00A76F" />
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.1002 29.7695C23.5618 29.7695 22.3146 31.0167 22.3146 32.5551C22.3146 34.0935 23.5618 35.3407 25.1002 35.3407C26.6386 35.3407 27.8857 34.0935 27.8857 32.5551C27.8857 31.0167 26.6386 29.7695 25.1002 29.7695V29.7695Z" fill="#007867" />
            <path opacity="0.72" fill-rule="evenodd" clip-rule="evenodd" d="M25.0999 30.0938C26.4594 30.0938 27.5614 31.1958 27.5614 32.5553C27.5614 33.9148 26.4594 35.0168 25.0999 35.0168C23.7404 35.0168 22.6384 33.9148 22.6384 32.5553C22.6395 31.1963 23.7409 30.0949 25.0999 30.0938Z" fill="#00A76F" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9482 32.7584L23.8135 32.6269C23.7843 32.6003 23.7678 32.5631 23.7678 32.5242C23.7678 32.4853 23.7843 32.4481 23.8135 32.4215L24.9953 31.2671C25.0226 31.2387 25.0607 31.2226 25.1005 31.2226C25.1404 31.2226 25.1785 31.2387 25.2058 31.2671L26.3876 32.4205C26.4162 32.4474 26.4323 32.4844 26.4323 32.5232C26.4323 32.5619 26.4162 32.599 26.3876 32.6259L26.2529 32.7573C26.2253 32.7852 26.1873 32.8009 26.1477 32.8009C26.108 32.8009 26.07 32.7852 26.0424 32.7573L25.3436 32.0384V33.7443C25.3439 33.7821 25.3287 33.8184 25.3015 33.8452C25.2742 33.872 25.2371 33.887 25.1984 33.887H25.0037C24.9229 33.887 24.8574 33.8231 24.8574 33.7443V32.0405L24.1586 32.7594C24.1309 32.7871 24.0929 32.8027 24.0532 32.8025C24.0135 32.8023 23.9756 32.7864 23.9482 32.7584Z" fill="#007867" />
            <path d="M4.82505 39.5082C4.82505 39.5082 6.13593 35.2105 4.61973 33.489C3.48532 32.2011 2.19751 32.3551 1.62078 32.5143C1.31898 32.5949 1.06771 32.8038 0.933332 33.0858C0.73148 33.5207 0.767813 34.2266 2.19116 35.0507C4.5736 36.4302 4.72181 38.3225 4.72181 38.3225L4.82505 39.5082Z" fill="#00A76F" />
            <path d="M4.8783 39.5422C4.8783 39.5422 3.3425 36.4187 2.92091 36.4856C2.72079 36.5184 2.62852 36.7163 2.58641 36.9135C2.53465 37.1694 2.56388 37.4351 2.67004 37.6736C2.88804 38.1754 3.47341 39.1241 4.8783 39.5422Z" fill="#00A76F" />
            <path d="M4.83258 39.5422C4.83258 39.5422 6.36781 36.4187 6.78939 36.4856C6.99009 36.5184 7.08237 36.7163 7.12389 36.9135C7.17566 37.1694 7.14643 37.4351 7.04027 37.6736C6.82227 38.1754 6.23632 39.1241 4.83258 39.5422Z" fill="#00A76F" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.78077 33.1098C1.78874 33.0844 1.8158 33.0702 1.84121 33.0782L1.82679 33.1242C1.84121 33.0782 1.84121 33.0782 1.84121 33.0782L1.8415 33.0783L1.84215 33.0785L1.84448 33.0793L1.85322 33.0821C1.86082 33.0847 1.87195 33.0885 1.88634 33.0937C1.91511 33.1041 1.95691 33.1199 2.00964 33.1418C2.1151 33.1857 2.26434 33.254 2.4405 33.3522C2.79276 33.5485 3.25322 33.8646 3.68645 34.3441C4.55448 35.3048 5.3082 36.9158 4.8733 39.5165C4.86891 39.5428 4.84405 39.5605 4.81778 39.5561C4.79151 39.5517 4.77378 39.5268 4.77817 39.5006C5.20836 36.9281 4.46261 35.347 3.61489 34.4088C3.19024 33.9388 2.73877 33.6288 2.39355 33.4364C2.22098 33.3402 2.0751 33.2735 1.97262 33.2309C1.92139 33.2096 1.88102 33.1943 1.85361 33.1845C1.8399 33.1795 1.82944 33.1759 1.82248 33.1736L1.81469 33.171L1.81284 33.1704L1.81243 33.1703C1.78702 33.1623 1.77281 33.1352 1.78077 33.1098Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.12407 33.5304C4.13895 33.5525 4.13313 33.5824 4.11107 33.5973L4.11091 33.5974L4.11005 33.598L4.1062 33.6007C4.10273 33.6032 4.0975 33.607 4.09081 33.6122C4.07742 33.6224 4.05824 33.6377 4.03562 33.6579C3.99028 33.6982 3.93171 33.7573 3.8784 33.8321C3.77185 33.9817 3.68861 34.1905 3.76733 34.4408C3.77532 34.4663 3.7612 34.4933 3.73579 34.5013C3.71038 34.5093 3.68331 34.4952 3.67532 34.4698C3.58449 34.1809 3.68262 33.9407 3.79984 33.7761C3.85841 33.6939 3.92233 33.6296 3.9715 33.5858C3.99613 33.5639 4.01721 33.547 4.03227 33.5355C4.03981 33.5297 4.04585 33.5253 4.05009 33.5223L4.05507 33.5188L4.05648 33.5178L4.0569 33.5175L4.05709 33.5174C4.05709 33.5174 4.05713 33.5174 4.08315 33.556L4.05709 33.5174C4.07918 33.5025 4.10919 33.5083 4.12407 33.5304Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.24869 33.9107L2.24915 33.9102L2.25143 33.908C2.25356 33.9059 2.25686 33.9028 2.26128 33.8989C2.27014 33.8909 2.28343 33.8795 2.30068 33.8662C2.33525 33.8397 2.38518 33.8061 2.44652 33.778C2.56892 33.722 2.73401 33.6887 2.91556 33.7724C2.93974 33.7836 2.96839 33.773 2.97955 33.7489C2.99071 33.7247 2.98015 33.696 2.95597 33.6849C2.74131 33.5858 2.54567 33.6266 2.40637 33.6904C2.33687 33.7222 2.28072 33.76 2.24192 33.7898C2.22248 33.8047 2.20727 33.8177 2.19679 33.8271C2.19154 33.8319 2.18747 33.8357 2.18464 33.8384L2.18132 33.8416L2.18036 33.8426L2.18006 33.8429L2.17992 33.843C2.17992 33.843 2.17989 33.8431 2.21429 33.8769L2.17992 33.843C2.16125 33.862 2.16149 33.8926 2.18049 33.9113C2.19948 33.9299 2.23002 33.9297 2.24869 33.9107Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76377 35.5843L3.76398 35.5842L3.76499 35.5837L3.76935 35.5816C3.77326 35.5798 3.77913 35.577 3.78672 35.5736C3.80192 35.5667 3.82395 35.5572 3.85089 35.5469C3.90502 35.5262 3.9778 35.5026 4.0542 35.4899C4.13119 35.477 4.20783 35.476 4.27185 35.4965C4.33363 35.5163 4.38545 35.5565 4.41657 35.6328C4.42664 35.6574 4.45479 35.6692 4.47945 35.6592C4.50411 35.6491 4.51593 35.621 4.50587 35.5963C4.46317 35.4917 4.38818 35.4324 4.30128 35.4046C4.21661 35.3775 4.12268 35.3807 4.03834 35.3947C3.9534 35.4089 3.87407 35.4348 3.81642 35.4568C3.78747 35.4679 3.7637 35.4781 3.74707 35.4856C3.73874 35.4894 3.73219 35.4925 3.72766 35.4946L3.72239 35.4972L3.72095 35.4979L3.72053 35.4981L3.72036 35.4982C3.72036 35.4982 3.72032 35.4982 3.74204 35.5413L3.72036 35.4982C3.69658 35.5102 3.687 35.5392 3.69899 35.563C3.71099 35.5868 3.74 35.5963 3.76377 35.5843Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90502 37.0403C2.92694 37.0252 2.95697 37.0307 2.97209 37.0526L2.93239 37.08L2.89269 37.1073C2.87757 37.0854 2.88309 37.0554 2.90502 37.0403ZM2.93239 37.08C2.89269 37.1073 2.89269 37.1073 2.89269 37.1073L2.91093 37.1337C2.92283 37.1509 2.94031 37.1761 2.96267 37.2082C3.00739 37.2723 3.07161 37.3641 3.14965 37.4745C3.3057 37.6954 3.51707 37.991 3.73823 38.2899C3.9593 38.5887 4.19057 38.8913 4.38633 39.1259C4.48415 39.2431 4.57371 39.3441 4.64908 39.4193C4.68674 39.4569 4.72149 39.4887 4.75244 39.5131C4.78248 39.5367 4.8124 39.5562 4.84008 39.5653C4.86538 39.5736 4.89264 39.5599 4.90098 39.5346C4.90932 39.5093 4.89557 39.482 4.87028 39.4737C4.85903 39.47 4.83986 39.4592 4.81209 39.4373C4.78522 39.4161 4.75342 39.3872 4.71721 39.3511C4.64485 39.2789 4.55743 39.1804 4.46039 39.0641C4.26643 38.8317 4.03647 38.5308 3.81577 38.2326C3.59516 37.9344 3.38423 37.6394 3.22842 37.4189C3.15052 37.3086 3.08642 37.217 3.0418 37.153C3.01949 37.121 3.00206 37.0959 2.9902 37.0788L2.97209 37.0526C2.97209 37.0526 2.97209 37.0526 2.93239 37.08Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.73591 37.445C2.73591 37.4184 2.7575 37.3968 2.78414 37.3968H3.16016C3.18679 37.3968 3.20838 37.4184 3.20838 37.445C3.20838 37.4717 3.18679 37.4932 3.16016 37.4932H2.78414C2.7575 37.4932 2.73591 37.4717 2.73591 37.445Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.18637 38.2977C3.18752 38.271 3.21001 38.2504 3.23662 38.2515L3.82661 38.2769C3.85322 38.2781 3.87386 38.3006 3.87272 38.3272C3.87157 38.3538 3.84908 38.3744 3.82247 38.3733L3.23248 38.3479C3.20587 38.3468 3.18523 38.3243 3.18637 38.2977Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60359 37.5685C3.62967 37.5739 3.6464 37.5995 3.64097 37.6256L3.57696 37.933C3.57153 37.959 3.54599 37.9758 3.51991 37.9703C3.49384 37.9649 3.4771 37.9394 3.48253 37.9133L3.54655 37.6059C3.55198 37.5798 3.57752 37.5631 3.60359 37.5685Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.22265 38.5024C4.24927 38.5035 4.26995 38.526 4.26885 38.5526L4.25731 38.8317C4.25621 38.8583 4.23375 38.879 4.20714 38.8779C4.18053 38.8768 4.15985 38.8544 4.16094 38.8278L4.17248 38.5486C4.17358 38.522 4.19604 38.5013 4.22265 38.5024Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.73888 37.0526C6.75402 37.0306 6.78406 37.0252 6.80597 37.0403C6.82788 37.0554 6.83337 37.0855 6.81823 37.1074L6.77855 37.08L6.73888 37.0526ZM6.73888 37.0526C6.73888 37.0526 6.73888 37.0526 6.77855 37.08C6.81823 37.1074 6.81823 37.1074 6.81823 37.1074L6.79995 37.1338C6.78803 37.1509 6.77051 37.1761 6.7481 37.2082C6.70329 37.2724 6.63895 37.3641 6.56078 37.4746C6.40445 37.6954 6.19274 37.9911 5.97132 38.29C5.75001 38.5887 5.51858 38.8913 5.32291 39.1259C5.22514 39.2431 5.1357 39.3441 5.06053 39.4193C5.02297 39.4569 4.98834 39.4887 4.95755 39.513C4.92766 39.5367 4.89791 39.5561 4.87041 39.5653C4.84514 39.5737 4.81784 39.56 4.80943 39.5347C4.80102 39.5095 4.81469 39.4822 4.83996 39.4737C4.85103 39.4701 4.87005 39.4592 4.89772 39.4374C4.92448 39.4162 4.95618 39.3873 4.99231 39.3511C5.06451 39.2789 5.15184 39.1804 5.24885 39.0641C5.44273 38.8317 5.67286 38.5308 5.89382 38.2325C6.11468 37.9344 6.32596 37.6394 6.48205 37.4189C6.56009 37.3086 6.62431 37.217 6.66902 37.153C6.69138 37.121 6.70885 37.0958 6.72073 37.0787L6.73888 37.0526Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50238 37.445C6.50238 37.4184 6.52397 37.3968 6.55061 37.3968H6.92663C6.95326 37.3968 6.97485 37.4184 6.97485 37.445C6.97485 37.4717 6.95326 37.4932 6.92663 37.4932H6.55061C6.52397 37.4932 6.50238 37.4717 6.50238 37.445Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52436 38.2977C6.5255 38.3243 6.50486 38.3468 6.47825 38.3479L5.88826 38.3733C5.86165 38.3744 5.83915 38.3538 5.83801 38.3272C5.83686 38.3006 5.85751 38.2781 5.88412 38.2769L6.4741 38.2515C6.50071 38.2504 6.52321 38.271 6.52436 38.2977Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.10712 37.5685C6.1332 37.5631 6.15874 37.5798 6.16417 37.6059L6.22818 37.9133C6.23361 37.9394 6.21688 37.9649 6.1908 37.9703C6.16473 37.9758 6.13919 37.959 6.13376 37.933L6.06974 37.6256C6.06431 37.5995 6.08105 37.5739 6.10712 37.5685Z" fill="#007867" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48812 38.5024C5.51473 38.5013 5.5372 38.522 5.5383 38.5486L5.54983 38.8278C5.55093 38.8544 5.53025 38.8768 5.50364 38.8779C5.47703 38.879 5.45456 38.8583 5.45346 38.8317L5.44193 38.5526C5.44083 38.526 5.46151 38.5035 5.48812 38.5024Z" fill="#007867" />
            <defs>
                <linearGradient id="paint0_linear_5087_82127" x1="19.8142" y1="15.7507" x2="10.4706" y2="43.3404" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00A76F" />
                    <stop offset="1" stop-color="#007867" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default IconFileUploaderIllustration;