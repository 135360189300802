import styles from "./KnowledgeDomainModal.module.css";

import { KnowledgeDomain } from "../../api";

import { useContext } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { Modal } from "@fluentui/react";

import IconKnowledgeDomainDefault from "../common/IconKnowledgeDomainDefault";

interface KnowledgeDomainButtonProps {
    setKnowledgeDomain: (knowledgeDomain: KnowledgeDomain) => void;
};

const KnowledgeDomainModal: React.FunctionComponent<KnowledgeDomainButtonProps> = ({ setKnowledgeDomain }) => {
    const appStateContext = useContext(AppStateContext);

    const processSelectedKnowledgeDomain = (knowledgeDomain: KnowledgeDomain) => {
        if (knowledgeDomain.disabled) {
            return;
        }
        setKnowledgeDomain(knowledgeDomain);
    };

    return (
        <Modal
            isOpen={appStateContext?.state.openedKnowledgeDomainModal}
            isBlocking={true}
            containerClassName={styles["knowledge-domain-modal"]}
            scrollableContentClassName={styles["knowledge-domain-modal__scrollable-content"]}
            overlay={{ styles: { root: { backgroundColor: 'rgba(22, 28, 36, 0.48)', backdropFilter: 'blur(5px)' } } }}
        >
            <div className={styles["knowledge-domain-modal__header"]}>
                <div className={styles["knowledge-domain-modal__logo-container"]}>
                    {
                        appStateContext?.state.tenantSettings?.header.logoPath ?? 0 > 0 ?
                            <img className={styles["knowledge-domain-modal__logo"]} src={appStateContext?.state.tenantSettings?.header.logoPath} />
                            : <div className={`${styles["knowledge-domain-modal__logo"]} ${styles["knowledge-domain-modal__logo--placeholder"]}`}></div>
                    }
                </div>
                <div className={styles["knowledge-domain-modal__title"]}>Select a knowledge domain</div>
                <div className={styles["knowledge-domain-modal__description"]}>This will help the knowledge assistant provide specific results</div>
            </div>
            <div className={styles["knowledge-domain-modal__body"]}>
                {
                    ((appStateContext?.state.tenantSettings?.knowledgeDomains?.filter(knowledgeDomain => !knowledgeDomain.disabled).length ?? 0) == 0) ?
                        <div className={styles["knowledge-domain-modal__list--empty"]}>No knowledge domains have been configured for this tenant.</div>
                        : <div className={styles["knowledge-domain-modal__list"]}>
                            {
                                appStateContext?.state.tenantSettings?.knowledgeDomains
                                    .filter(knowledgeDomain => !knowledgeDomain.disabled)
                                    .map(
                                        knowledgeDomain =>
                                            <div
                                                key={`knowledge-domain-modal-list-item__${knowledgeDomain.id}`}
                                                className={`${styles["knowledge-domain-modal-list-item"]} ${knowledgeDomain.disabled ? styles["knowledge-domain-modal-list-item--disabled"] : ''}`}
                                                onClick={() => { processSelectedKnowledgeDomain(knowledgeDomain); }}
                                            >
                                                <div className={styles["knowledge-domain-modal-list-item__icon-container"]}>
                                                    {
                                                        knowledgeDomain.iconUrl ?? 0 > 0 ?
                                                            <img className={styles["knowledge-domain-modal-list-item__icon"]} src={knowledgeDomain.iconUrl} />
                                                            : <IconKnowledgeDomainDefault />
                                                    }
                                                </div>
                                                <div className={styles["knowledge-domain-modal-list-item__body"]}>
                                                    <div className={styles["knowledge-domain-modal-list-item__title"]}>{knowledgeDomain.label}</div>
                                                    <div className={styles["knowledge-domain-modal-list-item__description"]}>{knowledgeDomain.description}</div>
                                                </div>
                                            </div>
                                    )
                            }
                        </div>
                }
            </div>
        </Modal>
    );
};

export default KnowledgeDomainModal;