import styles from "./FileUploadDropzone.module.css";

import { useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { useLocation } from "react-router-dom";

import { UploadedFile, UploadedFileOrigin, UploadFileStatus } from "../../api";

import IconFileUploaderIllustration from "../common/IconFileUploaderIllustration";

import { Stack, Text } from "@fluentui/react";

import { useUploadedFileStore } from "../../store/UploadedFiles.store";

import { APP_ROUTE_REPORT_GENERATOR } from "../../constants/routeNames";

interface FileUploadDropzoneProps {
    filesOrigin: UploadedFileOrigin;
    onUploadFiles?: (files: UploadedFile[]) => UploadedFile[];
    afterOnUploadFiles?: () => void;
};

const FileUploadDropzone: React.FunctionComponent<FileUploadDropzoneProps> = ({ filesOrigin, onUploadFiles, afterOnUploadFiles }) => {
    const { pathname: appLocation } = useLocation();

    const addUploadedFiles = useUploadedFileStore((state) => state.addNewFiles);

    const onDrop = useCallback(
        (files: File[]) => {
            if (files.length === 0) {
                return;
            }

            const currentDate = new Date()
                .toISOString()
                .split('T')[0];

            const uploadedFiles: UploadedFile[] = files.map(
                file => {
                    const modifiedFile = new File([file], `${currentDate} ${file.name}`);
                    const resultFile: UploadedFile = {
                        name: modifiedFile.name,
                        file: modifiedFile,
                        status: UploadFileStatus.Uploading,
                        origin: filesOrigin,
                    };
                    return resultFile;
                }
            );

            let customizedUploadedFiles: UploadedFile[] = uploadedFiles;

            if (onUploadFiles) {
                customizedUploadedFiles = onUploadFiles(uploadedFiles);
            }

            addUploadedFiles(customizedUploadedFiles);

            if (afterOnUploadFiles) {
                afterOnUploadFiles();
            }
        },
        []
    );

    const { getRootProps, getInputProps } = useDropzone({
        noDragEventsBubbling: true,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        },
        onDrop: onDrop,
    });

    return (
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <div {...getRootProps({ className: `${styles["file-upload-dropzone"]} ${appLocation == APP_ROUTE_REPORT_GENERATOR ? styles["file-upload-dropzone--report"] : ""}` })}>
                <input {...getInputProps()} />
                {
                    appLocation == APP_ROUTE_REPORT_GENERATOR
                    && <IconFileUploaderIllustration />
                }
                {
                    appLocation != APP_ROUTE_REPORT_GENERATOR
                    && <div className={styles["file-upload-dropzone__drag-text"]} aria-label="Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)">
                        <Text>Drag & Drop Files Here, or Click to Upload. (Only pdf, doc and docx files are supported)</Text>
                    </div>
                }
                {
                    appLocation == APP_ROUTE_REPORT_GENERATOR
                    && <Text
                        className={styles["file-upload-dropzone__drag-text"]}
                        aria-label="Drop files here or click to browse through your device."
                    >
                        Drop files here or click to <span className={`${styles["file-upload-dropzone__drag-text"]} ${styles["file-upload-dropzone__drag-text--underline"]}`}>browse</span> through your device.
                    </Text>
                }
            </div>
        </Stack>
    );
};

export default FileUploadDropzone;