import styles from "./ProfilePanel.module.css";

import { UserInfo } from "../../api";

import { useEffect, useState } from "react";

import md5Hex from "md5-hex";

interface ProfilePanelProps {
    user?: UserInfo | null;
};

const ProfilePanel: React.FunctionComponent<ProfilePanelProps> = ({ user }) => {
    const userClaims: Array<{ typ: string, val: string } | undefined> = user?.user_claims as Array<{ typ: string, val: string } | undefined>;
    const userNameClaim = userClaims?.find(x => x?.typ === "name");
    const username = userNameClaim?.val ?? "";
    const userEmailClaim = userClaims?.find(x => x?.typ === "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress");
    const userEmail = userEmailClaim?.val ?? "";

    const [avatarUrl, setAvatarUrl] = useState<string>("");

    useEffect(
        () => {
            const fetchGravatar = async () => {
                if (!userEmail || userEmail.length < 1) {
                    return;
                }

                const baseUrl = new URL('https://gravatar.com/avatar/');
                baseUrl.pathname += md5Hex(userEmail);
                baseUrl.searchParams.append('size', '40');
                baseUrl.searchParams.append('default', '404');
                const url = baseUrl.toString();

                try {
                    const response = await fetch(url);

                    if (!response.ok) {
                        return;
                    }

                    const blob = await response.blob();
                    const blobURL = URL.createObjectURL(blob);

                    setAvatarUrl(blobURL);
                } catch {
                    return;
                }
            };

            fetchGravatar();
        },
        [userEmail]
    );

    const renderAvatar = () => {
        if (avatarUrl.length > 0) {
            return <img className={styles["profile-panel__avatar"]} src={avatarUrl} />;
        } else if (username.length > 0) {
            const usernameInitials = username.trim().split(" ").reduce((acc, word, i, arr) => i === 0 || i === arr.length - 1 ? acc + word[0].toUpperCase() : acc, "").trim();
            return <div className={styles["profile-panel__avatar-initials"]}>{usernameInitials}</div>;
        } else {
            return <div className={styles["profile-panel__avatar-placeholder"]}></div>;
        }
    };

    const logOut = (): void => {
        window.close();
    };

    return (
        <div className={styles["profile-panel"]}>
            <div className={styles["profile-panel__avatar-container"]}>
                {
                    renderAvatar()
                }
            </div>
            <div className={styles["profile-panel__user"]}>
                <div className={styles["profile-panel__username"]}>{username && username.trim().length > 0 ? username : "Unknown user"}</div>
                <div className={styles["profile-panel__actions"]}>
                    <span onClick={logOut}>Log out</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span onClick={() => { window.open("https://madisonai.com/contact/", "_blank"); }}>Support</span>
                </div>
            </div>
        </div>
    );
};

export default ProfilePanel;