const IconExportConversationToPDF: React.FunctionComponent = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.172 0C11.7022 0 12.2107 0.210507 12.5857 0.585255L17.9137 5.90924C18.2891 6.28436 18.5 6.79329 18.5 7.32398V17.6667C18.5 18.9553 17.4344 20 16.12 20H3.88C2.56556 20 1.5 18.9553 1.5 17.6667V2.33333C1.5 1.04467 2.56556 0 3.88 0H11.172Z" fill="#E94848" />
            <g filter="url(#filter0_d_4986_42598)">
                <path d="M17.1548 5.64301C17.4678 5.95858 17.2443 6.49512 16.7998 6.49512H14.5C13.2113 6.49512 12.0488 5.36163 12.0488 4.10515V1.70923C12.0488 1.26279 12.5894 1.04016 12.9038 1.35712L17.1548 5.64301Z" fill="white" fill-opacity="0.24" shape-rendering="crispEdges" />
            </g>
            <path d="M8.03333 7.99512C7.60654 7.99512 7.25556 8.3461 7.25556 8.7729C7.25556 9.30346 7.55035 9.96213 7.86076 10.5812C7.61812 11.3408 7.34278 12.1548 6.99063 12.8416C6.26986 13.1245 5.62708 13.3347 5.24062 13.6462C5.2346 13.6523 5.22892 13.6588 5.22361 13.6656C5.08239 13.81 5 14.0066 5 14.2173C5 14.6441 5.35099 14.9951 5.77778 14.9951C5.9859 14.9951 6.18678 14.9175 6.33194 14.7691C6.33697 14.7652 6.34183 14.7612 6.34653 14.7569C6.631 14.4172 6.96642 13.8011 7.26528 13.2378C7.95406 12.9669 8.67596 12.6912 9.37257 12.5257C9.8811 12.9355 10.6168 13.2062 11.2222 13.2062C11.649 13.2062 12 12.8552 12 12.4285C12 12.0017 11.649 11.6507 11.2222 11.6507C10.7367 11.6507 10.0311 11.824 9.49167 12.0055C9.05439 11.595 8.65264 11.0823 8.36389 10.5375C8.5702 9.90192 8.81111 9.26598 8.81111 8.7729C8.81111 8.3461 8.46012 7.99512 8.03333 7.99512ZM8.03333 8.46178C8.20792 8.46178 8.34444 8.59831 8.34444 8.7729C8.34444 9.00634 8.21944 9.43615 8.07465 9.89824C7.88119 9.44871 7.72222 9.01742 7.72222 8.7729C7.72222 8.59831 7.85875 8.46178 8.03333 8.46178ZM8.16701 11.1232C8.40094 11.4953 8.67492 11.8416 8.97396 12.1489C8.51271 12.2751 8.06225 12.4367 7.61771 12.6059C7.83242 12.1213 8.0043 11.6184 8.16701 11.1232ZM11.2222 12.1173C11.3968 12.1173 11.5333 12.2539 11.5333 12.4285C11.5333 12.603 11.3968 12.7396 11.2222 12.7396C10.8717 12.7396 10.3736 12.5813 9.97535 12.3604C10.4325 12.2311 10.9312 12.1173 11.2222 12.1173ZM6.57986 13.51C6.36114 13.9005 6.14373 14.2649 5.99167 14.4482C5.93972 14.4979 5.87062 14.5285 5.77778 14.5285C5.60319 14.5285 5.46667 14.3919 5.46667 14.2173C5.46667 14.1352 5.50158 14.0536 5.54931 14.001C5.73176 13.8598 6.1287 13.6906 6.57986 13.51Z" fill="white" />
            <defs>
                <filter id="filter0_d_4986_42598" x="10.0488" y="1.20822" width="9.25195" height="9.2869" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4986_42598" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4986_42598" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default IconExportConversationToPDF;