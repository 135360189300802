import styles from "./ReportGenerator.module.css";

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { AppStateContext } from "../../state/AppProvider";

import { ChatState, useChatStore } from "../../store/Chat.store";
import { useUploadedFileStore } from "../../store/UploadedFiles.store";

import {
    KnowledgeDomainDB,
    ReportRequest,
    ReportTemplate,
    ReportTemplateDB,
    UploadedFile,
    UploadedFileOrigin,
    UploadFileStatus
} from "../../api";

import QuestionInput from "../../components/QuestionInput/QuestionInput";

import IconCloseReportGenerator from "../../components/common/IconCloseReportGenerator";
import IconCheckedReportTemplate from "../../components/common/IconCheckedReportTemplate";
import IconFileUploaderLoading from "../../components/common/IconFileUploaderLoading";

import FileUploadDropzone from "../../components/FileUpload/FileUploadDropzone";
import FileUploadList from "../../components/FileUpload/FileUploadList";

import { APP_ROUTE_REPORT, APP_ROUTE_ROOT } from "../../constants/routeNames";

interface ReportGeneratorStepErrorMessage {
    show: boolean;
    text: string;
};

const ReportGenerator: React.FunctionComponent = () => {
    type ReportTemplateSelection = ReportTemplate & {
        selected: boolean;
    };

    const reportIdRef = useRef<string>(uuid());

    const navigate = useNavigate();
    const appStateContext = useContext(AppStateContext);

    const [reportTemplates, setReportTemplates] = useState<ReportTemplateSelection[]>([]);
    const reportIntent = useChatStore((state: ChatState) => state.userPrompt);

    const initialErrorMessage: ReportGeneratorStepErrorMessage = {
        show: false,
        text: ""
    };
    const [intentErrorMessage, setIntentErrorMessage] = useState(initialErrorMessage);
    const [templateErrorMessage, setTemplateErrorMessage] = useState(initialErrorMessage);

    const uploadedFiles = useUploadedFileStore((state) => state.files);

    const loadReportTemplatesFromKnowledgeDomain = () => {
        if (!appStateContext?.state.isValidCurrentKnowledgeDomain || reportTemplates.length > 0) {
            return;
        }

        // TODO
        // Fetch from AdminPanel API latest report templates for the current knowledge domain
        const currentKnowledgeDomainReportTemplates = appStateContext?.state.currentKnowledgeDomain?.reportTemplates ?? [];
        const templatesWithSelection: ReportTemplateSelection[] = currentKnowledgeDomainReportTemplates.map(
            template => ({
                ...template,
                selected: false,
            })
        );
        setReportTemplates(templatesWithSelection);
    };

    useEffect(
        () => {
            appStateContext?.dispatch({ type: "SET_HIDDEN_RIGHT_PANE", payload: true });
            appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: false });
            appStateContext?.dispatch({ type: "SET_WEBBROWSING_ENABLED", payload: false });
            useUploadedFileStore.setState({ files: [] });
            useChatStore.setState({ userPrompt: "" });

            loadReportTemplatesFromKnowledgeDomain();

            const currentConversation = appStateContext?.state.currentChat;
            if (currentConversation && currentConversation.id) {
                reportIdRef.current = currentConversation.id;
                useChatStore.setState({ userPrompt: currentConversation.title });
                const previousAssociatedFileNames = currentConversation.associated_filenames ?? [];
                const previousUploadedFiles: UploadedFile[] = previousAssociatedFileNames.map(
                    previousFileName => {
                        const fileData: UploadedFile = {
                            name: previousFileName,
                            status: UploadFileStatus.Completed,
                            origin: UploadedFileOrigin.Report,
                        };
                        return fileData;
                    }
                );
                if (previousUploadedFiles && previousUploadedFiles.length > 0) {
                    useUploadedFileStore.setState({ files: previousUploadedFiles });
                }
            }

            return () => {
                useUploadedFileStore.setState({ files: [] });
                useChatStore.setState({ userPrompt: "" });
                appStateContext?.dispatch({ type: "SET_HIDDEN_RIGHT_PANE", payload: false });
            };
        },
        []
    );

    const closeReportGenerator = () => {
        appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
        navigate(APP_ROUTE_ROOT);
        return;
    };

    useEffect(
        () => {
            loadReportTemplatesFromKnowledgeDomain();
        },
        [appStateContext?.state.currentKnowledgeDomain, appStateContext?.state.isValidCurrentKnowledgeDomain]
    );

    const handleTemplateClick = (id: string) => {
        setReportTemplates(prevTemplates =>
            prevTemplates.map(
                template =>
                    template.id === id ?
                        { ...template, selected: !template.selected }
                        : { ...template, selected: false }
            )
        );
    };

    const onUploadFiles = (files: UploadedFile[]): UploadedFile[] => {
        // Associate the uploaded file to the report
        // Injecting the report Id to the file name to achieve this
        const renamedFiles: UploadedFile[] = files.map(
            file => {
                const modifiedFile = new File([file.file!], `${reportIdRef.current}_${file.name}`);
                const resultFile = { ...file, name: modifiedFile.name, file: modifiedFile };
                return resultFile;
            }
        );
        return renamedFiles;
    };

    const onGenerateDraft = () => {
        if (!reportIntent || reportIntent.trim().length < 1) {
            setIntentErrorMessage({ show: true, text: "Please specify your report intent" });
            setTimeout(
                () => {
                    setIntentErrorMessage(initialErrorMessage);
                },
                5000
            );
            return;
        }

        const template: ReportTemplateDB = reportTemplates.find(template => template.selected) as ReportTemplateDB;

        if (!template) {
            setTemplateErrorMessage({ show: true, text: "Please choose a template" });
            setTimeout(
                () => {
                    setTemplateErrorMessage(initialErrorMessage);
                },
                5000
            );
            return;
        }

        const knowledgeDomain: KnowledgeDomainDB = appStateContext?.state.currentKnowledgeDomain! as KnowledgeDomainDB;
        const associatedFileNames: string[] = uploadedFiles.map(file => file.name);
        const reportRequest: ReportRequest = {
            id: reportIdRef.current,
            template,
            knowledgeDomain,
            intent: reportIntent,
            associatedFileNames: associatedFileNames,
        };

        navigate(APP_ROUTE_REPORT, { state: { reportRequest } });
    };

    return (
        <div className={styles["report-generator"]}>
            <div className={styles["report-generator__top"]}>
                <div className={styles["report-generator-top__heading"]}>Draft a Staff Report</div>
                <div className={styles["report-generator-top__button-close"]} onClick={closeReportGenerator}>
                    <IconCloseReportGenerator />
                </div>
            </div>
            <div className={styles["report-generator__body"]}>
                <div className={styles["report-generator__sections"]}>
                    <div className={styles["report-generator__section"]}>
                        <div className={styles["report-generator__section-heading"]}>
                            <div className={styles["report-generator__section-step"]}>1</div>
                            <div className={styles["report-generator__section-text"]}>What is the subject of your staff report or memo?</div>
                        </div>
                        <div className={styles["report-generator__section-body"]}>
                            <QuestionInput disabled={false} placeholder="Start typing..." showSendButton={false} />
                            {
                                intentErrorMessage.show &&
                                <div className={styles["report-generator__step-intent-error"]}>{intentErrorMessage.text}</div>
                            }
                        </div>
                    </div>
                    <div className={styles["report-generator__section"]}>
                        <div className={styles["report-generator__section-heading"]}>
                            <div className={styles["report-generator__section-step"]}>2</div>
                            <div className={styles["report-generator__section-text"]}>Select a document template</div>
                        </div>
                        <div className={styles["report-generator__section-body"]}>
                            <div className={styles["report-generator__template-list"]}>
                                {
                                    reportTemplates
                                        .map(
                                            template => {
                                                return (
                                                    <div
                                                        key={`report-generator__template-list__item__${template.id}`}
                                                        className={`${styles["report-generator__template-list-item"]} ${template.selected ? styles["report-generator__template-list-item--selected"] : ""}`}
                                                        onClick={() => handleTemplateClick(template.id)}
                                                    >
                                                        <div className={styles["report-generator__template-list-item-content"]}>
                                                            <div className={styles["report-generator__template-list-item-name"]}>{template.name}</div>
                                                        </div>
                                                        <div className={styles["report-generator__template-list-item-checkbox-container"]}>
                                                            {
                                                                template.selected && <IconCheckedReportTemplate />
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )
                                }
                            </div>
                            {
                                templateErrorMessage.show &&
                                <div className={styles["report-generator__step-template-error"]}>{templateErrorMessage.text}</div>
                            }
                        </div>
                    </div>
                    <div className={styles["report-generator__section"]}>
                        <div className={styles["report-generator__section-heading"]}>
                            <div className={styles["report-generator__section-step"]}>3</div>
                            <div className={styles["report-generator__section-text"]}>Upload files</div>
                        </div>
                        <div className={styles["report-generator__section-body"]}>
                            <div className={styles["report-generator__file-uploader"]}>
                                <FileUploadDropzone filesOrigin={UploadedFileOrigin.Report} onUploadFiles={onUploadFiles} />
                                <FileUploadList filesOrigin={UploadedFileOrigin.Report} filterByIncludingId={reportIdRef.current} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["report-generator__button-generate-container"]}>
                    {
                        uploadedFiles
                            .some(
                                x => x.origin == UploadedFileOrigin.Report
                                    && x.status !== UploadFileStatus.Completed
                                    && x.name.includes(reportIdRef.current)
                            ) ?
                            <div className={`${styles["report-generator__button-generate"]} ${styles["report-generator__button-generate--processing"]}`}>
                                <IconFileUploaderLoading className={styles["report-generator__icon-file-processing"]} />
                                Processing files ...
                            </div>
                            : <div className={`${styles["report-generator__button-generate"]} ${styles["report-generator__button-generate--normal"]}`} onClick={() => onGenerateDraft()}>Generate draft</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ReportGenerator;