const IconExportConversationToTXT: React.FunctionComponent = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5381_41486)">
                <path d="M12.3719 0C12.9021 0 13.4106 0.210507 13.7856 0.585255L19.7636 6.55875C20.139 6.93387 20.3499 7.4428 20.3499 7.9735V19.4333C20.3499 20.8509 19.1778 22 17.7319 22H4.2679C2.82202 22 1.6499 20.8509 1.6499 19.4333V2.56667C1.6499 1.14914 2.82202 0 4.2679 0H12.3719Z" fill="#454F5B" />
                <g filter="url(#filter0_d_5381_41486)">
                    <path d="M18.9549 6.29255C19.2679 6.60812 19.0443 7.14465 18.5999 7.14465H15.95C14.5325 7.14465 13.2537 5.89782 13.2537 4.51569V1.75877C13.2537 1.31233 13.7944 1.08969 14.1087 1.40666L18.9549 6.29255Z" fill="white" fill-opacity="0.24" shape-rendering="crispEdges" />
                </g>
                <path d="M4.3999 13.4068V12.2782H8.4061V13.4068H7.0399V17.05H5.7529V13.4068H4.3999Z" fill="white" />
                <path d="M13.4047 17.05H11.8009L10.9231 15.5518L10.0651 17.05H8.69893L10.2565 14.641L8.73853 12.2782H10.3291L11.0683 13.5718L11.8009 12.2782H13.1473L11.7217 14.4562L13.4047 17.05Z" fill="white" />
                <path d="M13.6683 13.4068V12.2782H17.6745V13.4068H16.3083V17.05H15.0213V13.4068H13.6683Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_5381_41486" x="11.2537" y="1.25781" width="9.84717" height="9.88684" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5381_41486" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5381_41486" result="shape" />
                </filter>
                <clipPath id="clip0_5381_41486">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconExportConversationToTXT;