import { create } from "zustand";

export interface ChatState {
  userPrompt: string;
  setUserPrompt: (prompt: string) => void;
}

export const useChatStore = create<ChatState>(
  (set) => ({
    userPrompt: "",
    setUserPrompt: (prompt: string) => set({ userPrompt: prompt }),
  })
);