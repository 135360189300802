const IconSessionHistoryMenuItemElipsis: React.FunctionComponent = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99999 9.33333C8.73637 9.33333 9.33332 8.73638 9.33332 8C9.33332 7.26362 8.73637 6.66667 7.99999 6.66667C7.26361 6.66667 6.66666 7.26362 6.66666 8C6.66666 8.73638 7.26361 9.33333 7.99999 9.33333Z" fill="#078DEE" />
            <path d="M7.99999 4.66667C8.73637 4.66667 9.33332 4.06971 9.33332 3.33333C9.33332 2.59695 8.73637 2 7.99999 2C7.26361 2 6.66666 2.59695 6.66666 3.33333C6.66666 4.06971 7.26361 4.66667 7.99999 4.66667Z" fill="#078DEE" />
            <path d="M7.99999 14C8.73637 14 9.33332 13.403 9.33332 12.6667C9.33332 11.9303 8.73637 11.3333 7.99999 11.3333C7.26361 11.3333 6.66666 11.9303 6.66666 12.6667C6.66666 13.403 7.26361 14 7.99999 14Z" fill="#078DEE" />
        </svg>
    );
};

export default IconSessionHistoryMenuItemElipsis;