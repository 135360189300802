import styles from "./FileUploadModal.module.css";

import { UploadedFile, UploadedFileOrigin } from "../../api";

import { IconButton, Modal, Stack, Text } from "@fluentui/react";

import FileUploadDropZone from "./FileUploadDropzone";

interface FileUploadModalProps {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    filesOrigin: UploadedFileOrigin;
    onUploadFiles?: (files: UploadedFile[]) => UploadedFile[];
};

const FileUploadModal: React.FunctionComponent<FileUploadModalProps> = ({ isOpen, setOpen, filesOrigin, onUploadFiles }) => {
    const closeModal = () => {
        setOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={closeModal}
            isBlocking={true}
            containerClassName={styles.uploadModalContainer}
        >
            <div className={styles.modalHeader}>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <Text variant="large">Upload File(s)</Text>
                    <IconButton
                        iconProps={{
                            iconName: 'Cancel',
                            styles: {
                                root: { color: '#000000' }
                            }
                        }}
                        ariaLabel="Close modal"
                        onClick={closeModal}
                    />
                </Stack>
            </div>

            <div className={styles.modalBody}>
                <FileUploadDropZone filesOrigin={filesOrigin} afterOnUploadFiles={closeModal} onUploadFiles={onUploadFiles} />
            </div>
        </Modal>
    );
};

export default FileUploadModal;