import styles from "./StaffReport.module.css";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import { AppStateContext } from "../../state/AppProvider";

import { useChatStore } from "../../store/Chat.store";
import { ReportSectionSelection, useReportSectionStore } from "../../store/ReportSection.store";

import {
    ChatHistoryLoadingState, Conversation, ConversationType, CosmosDBStatus,
    ErrorMessage, generateReport, regenerateSection, ReportHeader,
    ReportHeaderType, ReportRequest, StreamingStatus, updateReportCosmosDB
} from "../../api";

import { Dialog, DialogType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import ReactMarkdown from "react-markdown";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import DOMPurify from "dompurify";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub';

import { APP_ROUTE_REPORT_GENERATOR, APP_ROUTE_ROOT } from "../../constants/routeNames";

const StaffReport: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const appStateContext = useContext(AppStateContext);
    const tenantSettings = appStateContext?.state.tenantSettings;

    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [reportHeader, setReportHeader] = useState<ReportHeader[]>([]);

    const reportSections = useReportSectionStore((state) => state.sections);
    const setReportSections = useReportSectionStore((state) => state.setReportSections);
    const setOnRephraseReportSection = useReportSectionStore((state) => state.setOnRephraseReportSection);
    const setOnStopReportSectionGeneration = useReportSectionStore((state) => state.setOnStopReportSectionGeneration);
    const setStreamingStatus = useReportSectionStore((state) => state.setStreamingStatus);

    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const sectionRefs = useRef<HTMLDivElement[]>([]);

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    const generateReportHeader = (reportRequest: ReportRequest): ReportHeader[] => {
        const template = JSON.parse(reportRequest.template.definition);
        const headerTemplate = template && template.header ? template.header as ReportHeader[] : [];
        const reportHeader: ReportHeader[] = headerTemplate.map(
            header => {
                const headerLabel: string = header.label ? `${header.label}: ` : "";
                let headerValue: string = header.value ?? "";
                switch (header.type) {
                    case ReportHeaderType.Date:
                        const currentDate = new Date().toISOString();
                        headerValue = currentDate.split("T")[0];
                        break;
                    case ReportHeaderType.Subject:
                        headerValue = reportRequest.intent
                        break;
                }
                const newReportHeader: ReportHeader = {
                    type: header.type,
                    value: `${headerLabel}${headerValue}`,
                };
                return newReportHeader;
            }
        );
        return reportHeader;
    };

    const generateStaffReport = async (reportRequest: ReportRequest) => {
        const controller = abortControllerRef.current;

        try {
            setStreamingStatus(StreamingStatus.Processing);

            let report: Conversation = {
                id: reportRequest.id!,
                report_sections: [],
                date: new Date().toISOString(),
                title: reportRequest.intent,
                knowledgeDomain: reportRequest.knowledgeDomain,
                type: ConversationType.Report,
                report_template: reportRequest.template,
                associated_filenames: reportRequest.associatedFileNames,
            }

            const reportHeader = generateReportHeader(reportRequest);

            report = { ...report, report_header: reportHeader };

            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: report });
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: report });

            const response = await generateReport(controller.signal, reportRequest);

            const reader = response.body!.getReader();

            let done, value;

            setReportHeader(reportHeader);

            while (!done) {
                ({ value, done } = await reader?.read());

                var buffer = new TextDecoder("utf-8").decode(value, { stream: true });

                processBuffer(buffer);

                if (done) {
                    await completeReportProcessing(report.id, report.report_header);
                    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
                    break;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setStreamingStatus(StreamingStatus.Done);
        }
    }

    const processBuffer = async (buffer: string, isRephrasing: boolean = false) => {
        let newLineIndex: number;
        while ((newLineIndex = buffer.indexOf("\n")) >= 0) {
            const line = buffer.slice(0, newLineIndex).trim();
            buffer = buffer.slice(newLineIndex + 1);

            if (!line) continue;

            try {
                const sections = JSON.parse(line);
                setReportSections(sections);

                if (!isRephrasing) {
                    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
                }

            } catch (error) {
                // console.error("Error parsing ND-JSON line:", error);
            }
        }
    }

    const completeReportProcessing = async (reportId: string, header?: ReportHeader[]) => {
        const sections = useReportSectionStore.getState().sections;

        if (appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            await updateReportCosmosDB({
                id: reportId!,
                header: header,
                sections: sections,
            });
        }

        appStateContext?.dispatch({
            type: 'UPDATE_REPORT_SECTIONS', payload: {
                reportId: reportId,
                sections: sections
            }
        });
        appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: { id: reportId } });
    }

    const rephraseReportSection = useCallback(
        async (instruction: string, reportId?: string) => {
            const currentReportSections = useReportSectionStore.getState().sections;
            const selectedSection = currentReportSections.find(section => section.selected);

            if (!selectedSection) {
                return;
            }

            const controller = abortControllerRef.current;

            const reportDefinition = appStateContext?.state.currentChat?.report_template?.definition;
            const reportInstructions = JSON.parse(reportDefinition!).report_instructions;

            try {
                setStreamingStatus(StreamingStatus.Processing);

                const response = await regenerateSection(
                    controller.signal,
                    {
                        reportInstructions: reportInstructions,
                        reportSections: currentReportSections,
                        reportIntent: appStateContext?.state.currentChat?.title!,
                        sectionId: selectedSection.id,
                        newSectionInstruction: instruction,
                        knowledgeDomain: appStateContext?.state.currentChat?.knowledgeDomain!,
                    }
                );

                const reader = response.body!.getReader();

                let done, value;

                while (!done) {
                    ({ value, done } = await reader?.read());

                    var buffer = new TextDecoder("utf-8").decode(value, { stream: true });

                    processBuffer(buffer, true);

                    if (done) {
                        await completeReportProcessing(reportId!);
                        break;
                    }
                }
            } catch (err) {
                console.log(err);
            } finally {
                setStreamingStatus(StreamingStatus.Done);
            }
        }
        , [appStateContext?.state.currentChat]
    );

    useEffect(
        () => {
            setOnRephraseReportSection(rephraseReportSection);
        },
        [rephraseReportSection]
    );

    const abortControllerRef = useRef(new AbortController());
    const signalControllerToAbort = () => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    };

    const resetReportGeneration = () => {
        navigate(APP_ROUTE_REPORT_GENERATOR);
    };
    const resetReportSectionGeneration = () => {
        const currentConversation = { ...appStateContext?.state.currentChat! };
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: currentConversation });
    };

    const stopReportProcessing = () => {
        signalControllerToAbort();

        if (!appStateContext?.state.currentChat?.report_sections || appStateContext.state.currentChat.report_sections.length < 1) {
            // Stop report generation
            resetReportGeneration();
        } else {
            // Stop section rephrasing
            resetReportSectionGeneration();
        }
    };

    useEffect(
        () => {
            signalControllerToAbort();

            let reportRequest: ReportRequest = locationState?.reportRequest;

            if (!reportRequest && !appStateContext?.state.currentChat) {
                navigate(APP_ROUTE_ROOT);
                return;
            }

            appStateContext?.dispatch({ type: "SET_HIDDEN_RIGHT_PANE", payload: false });
            appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });

            setOnRephraseReportSection(rephraseReportSection);
            setOnStopReportSectionGeneration(stopReportProcessing);

            if (reportRequest && reportRequest.intent && reportRequest.knowledgeDomain && reportRequest.template) {
                generateStaffReport(reportRequest);
                return;
            }

            if (appStateContext?.state.currentChat) {
                if (!appStateContext?.state.currentChat.report_sections || appStateContext?.state.currentChat.report_sections.length < 1) {
                    navigate(APP_ROUTE_REPORT_GENERATOR);
                    return;
                }

                const currentConversationReportHeader = appStateContext.state.currentChat.report_header ?? [];
                setReportHeader(currentConversationReportHeader);

                const currentConversationReportSections = appStateContext.state.currentChat.report_sections ?? [];
                const sectionsWithSelection: ReportSectionSelection[] = currentConversationReportSections.map(
                    section => ({
                        ...section,
                        id: section.id ?? uuid(),
                        selected: false,
                    })
                );
                setReportSections(sectionsWithSelection);

                return;
            }

            return () => {
                signalControllerToAbort();
                appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: false });
                setOnStopReportSectionGeneration();
                setOnRephraseReportSection();
                setStreamingStatus(StreamingStatus.NotRunning);
                setReportSections([]);
            };
        },
        []
    );

    useEffect(
        () => {
            if (
                tenantSettings?.visibility.history
                && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working
                && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
                && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail
                && hideErrorDialog
            ) {
                let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
                setErrorMsg({
                    title: "Chat history is not enabled",
                    subtitle: subtitle
                })
                toggleErrorDialog();
            }
        },
        [appStateContext?.state.isCosmosDBAvailable]
    );

    const handleErrorDialogClose = () => {
        toggleErrorDialog();
        setTimeout(
            () => {
                setErrorMsg(null);
            },
            500
        );
    };

    const handleSectionClick = (id: string, index: number) => {
        const currentReportSections = [...reportSections];
        setReportSections(
            currentReportSections.map(
                section => {
                    if (section.id === id) {
                        return { ...section, selected: !section.selected };
                    }
                    return { ...section, selected: false };
                }
            )
        );
        sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });
        useChatStore.setState({ userPrompt: "" });
    };

    return (
        <div className={styles["staff-report"]}>
            <Dialog
                hidden={hideErrorDialog}
                onDismiss={handleErrorDialogClose}
                dialogContentProps={errorDialogContentProps}
                modalProps={modalProps}
            >
            </Dialog>
            <div className={styles["staff-report__body"]}>
                <div className={styles["staff-report__header"]}>
                    {
                        reportHeader.map(
                            header => {
                                return (
                                    <div className={styles["staff-report__header-text"]}>
                                        {
                                            header.type === ReportHeaderType.Logo ? <img src={header.value} width={80} height={80} /> : header.value
                                        }
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <div className={styles["staff-report__sections"]}>
                    {
                        reportSections.map(
                            (section, index) => {
                                return (
                                    <div
                                        key={`staff-report__section__${section.id}`}
                                        ref={(el) => (sectionRefs.current[index] = el!)}
                                        className={`${styles["staff-report__section"]} ${section.selected ? styles["staff-report__section--selected"] : ""}`}
                                        onClick={() => handleSectionClick(section.id, index)}
                                    >
                                        <div className={`${styles["staff-report__section-text"]} ${styles["staff-report__section-text--heading"]}`}>{section.title}</div>
                                        <div className={`${styles["staff-report__section-text"]} ${styles["staff-report__section-text--content"]}`}>
                                            <ReactMarkdown
                                                children={DOMPurify.sanitize(section.content, { ALLOWED_TAGS: XSSAllowTags })}
                                                linkTarget="_blank"
                                                remarkPlugins={[remarkGfm, supersub]}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        )
                    }
                    <div ref={chatMessageStreamEnd} />
                </div>
            </div>
        </div>
    );
};

export default StaffReport;