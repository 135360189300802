import { create } from "zustand";
import { UploadedFile, UploadFileStatus } from "../api";

type UploadedFileStore = {
    files: UploadedFile[];
    addNewFiles: (files: UploadedFile[]) => void;
    updateFileStatus: (filename: string, status: UploadFileStatus) => void;
    removeFile: (filename: string) => void;
}

export const useUploadedFileStore = create<UploadedFileStore>(
    (set) => ({
        files: [],
        addNewFiles: (newFiles: UploadedFile[]) => {
            set(
                (state) => ({ files: [...state.files, ...newFiles] })
            );
        },
        updateFileStatus: (filename: string, newStatus: UploadFileStatus) => {
            set(
                (state) => ({ files: state.files.map((file) => file.name === filename ? { ...file, status: newStatus } : file) })
            );
        },
        removeFile: (filename: string) => {
            set(
                (state) => {
                    const withoutFile = state.files.filter((file) => file.name !== filename);
                    const newFiles = [...withoutFile];
                    return { files: newFiles };
                }
            );
        },
    })
);