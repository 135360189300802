import styles from "./Layout.module.css";

import { useContext, useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ConversationType, CosmosDBStatus, KnowledgeDomain, StreamingStatus } from "../../api";

import { useChatStore } from "../../store/Chat.store";
import { useReportSectionStore } from "../../store/ReportSection.store";

import { AppStateContext } from "../../state/AppProvider";

import {
    RIGHT_PANE_TAB_SOURCES_NAME,
    RIGHT_PANE_TAB_CITATIONS_NAME,
    RIGHT_PANE_TAB_EXPORT_NAME,
    RIGHT_PANE_TAB_SOURCES_LABEL_FOR_REPORT,
    RIGHT_PANE_TAB_SOURCES_LABEL_FOR_CONVERSATION
} from "../../constants/rightPane";
import { APP_ROUTE_REPORT, APP_ROUTE_ROOT } from "../../constants/routeNames";

import { createTheme, ISeparatorStyles, Pivot, PivotItem, Separator, ThemeProvider, Toggle } from "@fluentui/react";

import IconTooltipInfo from "../../components/common/IconTooltipInfo";
import { MenuItemIcon } from "../../components/common/MenuItemIcon";
import IconNewSession from "../../components/common/IconNewSession";
import IconRightPaneOpen from "../../components/common/IconRightPaneOpen";
import IconRightPaneClose from "../../components/common/IconRightPaneClose";

import TenantPanel from "../../components/Tenant/TenantPanel";
import KnowledgeDomainButton from "../../components/KnowledgeDomain/KnowledgeDomainButton";
import KnowledgeDomainModal from "../../components/KnowledgeDomain/KnowledgeDomainModal";
import ChatHistory from "../../components/ChatHistory/ChatHistory";
import ProfilePanel from "../../components/Profile/ProfilePanel";
import QuestionInput from "../../components/QuestionInput/QuestionInput";
import FileUpload from "../../components/FileUpload/FileUpload";
import Citation from "../../components/Citation/Citation";
import ConversationExport from "../../components/ConversationExport/ConversationExport";

const Layout = () => {
    const navigate = useNavigate();
    const { pathname: appLocation } = useLocation();

    const appStateContext = useContext(AppStateContext);
    const tenantSettings = appStateContext?.state.tenantSettings;

    const leftPane = useRef<HTMLDivElement>(null);
    const rightPane = useRef<HTMLDivElement>(null);

    const toggleLeftPane = () => {
        appStateContext?.dispatch({ type: "TOGGLE_LEFT_PANE", payload: !appStateContext?.state.expandedLeftPane });
    };

    const toggleRightPane = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: !appStateContext?.state.expandedRightPane });
    };

    const user = appStateContext?.state.user;

    const clearSession = (knowledgeDomain?: KnowledgeDomain) => {
        useChatStore.setState({ userPrompt: "" });
        appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
        appStateContext?.dispatch({ type: "SET_FOLLOW_UP_QUESTIONS", payload: [] });
        appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: RIGHT_PANE_TAB_SOURCES_NAME });
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: false });
        if (knowledgeDomain) {
            appStateContext?.dispatch({ type: "SET_KNOWLEDGE_DOMAIN", payload: knowledgeDomain });
        }
        navigate(APP_ROUTE_ROOT);
    };

    const openKnowledgeDomainModal = () => {
        appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: true });
    };
    const setKnowledgeDomain = (knowledgeDomain: KnowledgeDomain) => {
        appStateContext?.dispatch({ type: "SET_OPEN_KNOWLEDGE_DOMAIN_MODAL", payload: false });
        clearSession(knowledgeDomain);
    }

    const startNewSession = () => {
        clearSession();
    };

    const handleRightPaneTabClick = (tabName: string) => {
        if (tabName && tabName.length > 0) {
            appStateContext?.dispatch({ type: "SET_ACTIVE_RIGHT_PANE_TAB", payload: tabName });
        }
    };

    const handleClickOutsideLeftPane = (event: MouseEvent) => {
        if (leftPane.current && !leftPane.current.contains(event.target as Node) &&
            !((event.target as HTMLElement).classList.contains(styles["top-pane__home"]) || (event.target as HTMLElement).closest(`.${styles["top-pane__home"]}`))
        ) {
            appStateContext?.dispatch({ type: "TOGGLE_LEFT_PANE", payload: false });
        }
    }

    const setIsWebBrowsingEnabled = (checked: boolean) => {
        appStateContext?.dispatch({ type: "SET_WEBBROWSING_ENABLED", payload: checked });
    }

    useEffect(() => {
        if(appStateContext?.state.expandedLeftPane)
            document.addEventListener('click', handleClickOutsideLeftPane);

        return () => {
            if(appStateContext?.state.expandedLeftPane)
                document.removeEventListener('click', handleClickOutsideLeftPane);
        };
    }, [appStateContext?.state.expandedLeftPane]);

    const customWebBrowingToggleTheme = createTheme({
        semanticColors: {
            inputBackgroundChecked: '#8e33ff',
            inputBackgroundCheckedHovered: '#8e33ff'
        },
    });

    const leftPaneBlockSeparatorStyles: Partial<ISeparatorStyles> = {
        root: { padding: "0px" }
    };

    const reportSections = useReportSectionStore((state) => state.sections);
    const streamingStatus = useReportSectionStore((state) => state.streamingStatus);
    const onRephraseReportSection = useReportSectionStore((state) => state.onRephraseReportSection);
    const onStopReportSectionGeneration = useReportSectionStore((state) => state.onStopReportSectionGeneration);

    return (
        <div className={styles["layout"]}>
            <div className={styles["layout__top"]}>
                <div className={styles["layout-top__tenant"]}>
                    <TenantPanel logoUrl={tenantSettings?.header.logoPath} name={tenantSettings?.name ?? "MadisonAI"} madisonAiUrl={"https://madisonai.com/"} />
                </div>
                <div className={styles["layout-top__right-end"]}>
                    <ProfilePanel user={user} />
                </div>
            </div>
            <div className={styles["layout__main"]}>
                <div className={styles["top-pane"]}>
                    <div className={styles["top-pane__home"]} onClick={() => toggleLeftPane()}><MenuItemIcon /></div>
                    <div className={styles["top-pane__title"]}>{appStateContext?.state.currentKnowledgeDomain?.label}</div>
                    <div className={styles["top-pane__settings"]} onClick={(event) => toggleRightPane(event)}><div style={{ width: "2px" }}></div><IconRightPaneOpen /></div>
                </div>
                <div ref={leftPane} className={`${styles["left-pane"]} ${appStateContext?.state.expandedLeftPane ? styles['left-pane--expanded'] : ''}`}>
                    <div className={styles["left-pane__tenant"]}>
                        <TenantPanel logoUrl={tenantSettings?.header.logoPath} name={tenantSettings?.name ?? "MadisonAI"} madisonAiUrl={"https://madisonai.com/"} />
                        <Separator styles={leftPaneBlockSeparatorStyles} />
                    </div>
                    <div className={styles["left-pane__knowledge-domain"]}>
                        <div className={styles["left-pane__heading"]}>KNOWLEDGE DOMAIN</div>
                        <KnowledgeDomainButton openModal={openKnowledgeDomainModal} isDocked={true} />
                        <div className={styles["left-pane__button-new-session"]} onClick={startNewSession}><IconNewSession className={styles["left-pane__button-new-session-icon"]} />New session</div>
                    </div>
                    <Separator styles={leftPaneBlockSeparatorStyles} />
                    <div className={styles["left-pane__session-history"]}>
                        {
                            appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
                            && tenantSettings?.visibility.history
                            && (appStateContext?.state.chatHistory?.length ?? 0) > 0
                            && <>
                                <div className={styles["left-pane__heading"]}>SESSION HISTORY</div>
                                <ChatHistory />
                            </>
                        }
                    </div>
                    <div className={styles["left-pane__profile"]}>
                        <Separator styles={leftPaneBlockSeparatorStyles} />
                        <ProfilePanel user={user} />
                    </div>
                    <div className={styles["left-pane__end"]}></div>
                </div>
                <div className={styles["center-pane"]}>
                    <Outlet />
                </div>
                {
                    !appStateContext?.state.hiddenRightPane &&
                    <div ref={rightPane} className={`${styles["right-pane"]} ${appStateContext?.state.expandedRightPane ? styles["right-pane--expanded"] : ""}`}>
                        <div
                            className={`${styles["right-pane__icon-open"]} ${appStateContext?.state.expandedRightPane ? "" : styles["right-pane__icon-open--inverted"]}`}
                            onClick={(event) => toggleRightPane(event)}
                        >
                            <div style={{ width: "2px" }}></div>
                            <IconRightPaneOpen />
                        </div>
                        <div className={styles["right-pane__tabs"]}>
                            <Pivot
                                aria-label="right pane tabs"
                                styles={{
                                    text: { fontWeight: 500, fontSize: "14px", lineHeight: "22px" },
                                    link: { color: "#637381" },
                                    linkIsSelected: { color: '#1c252e', "::before": { backgroundColor: '#1c252e' } }
                                }}
                                selectedKey={appStateContext?.state.activeRightPaneTab}
                                onLinkClick={item => handleRightPaneTabClick(item?.props.itemKey!)}
                                headersOnly={true}
                            >
                                <PivotItem
                                    headerText={
                                        appLocation == APP_ROUTE_REPORT ?
                                            RIGHT_PANE_TAB_SOURCES_LABEL_FOR_REPORT
                                            : RIGHT_PANE_TAB_SOURCES_LABEL_FOR_CONVERSATION
                                    }
                                    itemKey={RIGHT_PANE_TAB_SOURCES_NAME}
                                />
                                {
                                    appLocation != APP_ROUTE_REPORT
                                    && <PivotItem headerText="Citations" itemKey={RIGHT_PANE_TAB_CITATIONS_NAME} />
                                }
                                {
                                    appStateContext?.state.currentChat
                                    && <PivotItem headerText="Export" itemKey={RIGHT_PANE_TAB_EXPORT_NAME} />
                                }
                            </Pivot>
                            <div className={styles["right-pane__icon-close"]} onClick={(event) => toggleRightPane(event)}>
                                <IconRightPaneClose />
                            </div>
                        </div>
                        {
                            appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_SOURCES_NAME &&
                            <div className={styles["right-pane__tab"]}>
                                {
                                    appLocation != APP_ROUTE_REPORT
                                    && <div className={styles["tenant-tooltip"]}>
                                        <div className={styles["tenant-tooltip__bubble"]}>
                                            <div className={styles["tenant-tooltip__info"]}>{tenantSettings?.tooltip.info}</div>
                                            {
                                                appStateContext?.state.currentKnowledgeDomain?.sharepointInformationLink
                                                && appStateContext?.state.currentKnowledgeDomain?.sharepointInformationLink.trim().length > 0
                                                && <div>
                                                    <a className={styles["tenant-tooltip__link"]} href={appStateContext?.state.currentKnowledgeDomain?.sharepointInformationLink} target={"_blank"}>Open directory</a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    appLocation == APP_ROUTE_REPORT
                                    && appStateContext?.state.currentChat?.id
                                    && appStateContext?.state.currentChat?.type == ConversationType.Report
                                    && <div className={styles["report-section-rephraser"]}>
                                        <div className={styles["report-section-rephraser__heading"]}>
                                            <div className={styles["report-section-rephraser__heading-text"]}>Adjust a section:</div>
                                            <IconTooltipInfo />
                                        </div>
                                        <QuestionInput
                                            disabled={!reportSections || reportSections.length < 1 || streamingStatus == StreamingStatus.Processing || !reportSections.some(x => x.selected)}
                                            isLoading={streamingStatus == StreamingStatus.Processing}
                                            placeholder={
                                                !reportSections
                                                    || reportSections.length < 1
                                                    || streamingStatus == StreamingStatus.Processing
                                                    || !reportSections.some(x => x.selected) ?
                                                    "Select a paragraph to rephrase."
                                                    : "Type to rephrase this section."
                                            }
                                            conversationId={appStateContext.state.currentChat.id}
                                            onSend={onRephraseReportSection}
                                            onStopGeneration={onStopReportSectionGeneration}
                                            textAreaContainerClassName={styles["report-section-rephraser__textArea-container"]}
                                            textAreaClassName={styles["report-section-rephraser__textArea"]}
                                            warningMessageClassName={styles["report-section-rephraser__warning-message"]}
                                        />
                                    </div>
                                }
                                {
                                    appStateContext?.state.currentChat?.type != ConversationType.Report
                                    && <div className={styles["web-browsing"]}>
                                        <div className={styles["web-browsing__heading"]}>
                                            <div className={styles["web-browsing__heading-text"]}>Web browsing:</div>
                                            <IconTooltipInfo />
                                        </div>
                                        <ThemeProvider theme={customWebBrowingToggleTheme}>
                                            <Toggle
                                                onText="Enabled"
                                                offText="Disabled"
                                                styles={{
                                                    root: { marginBottom: '0px' },
                                                    container: { alignItems: 'center' },
                                                    label: { fontWeight: 400, fontSize: '14px', lineHeight: '22px', color: '#1c252e' },
                                                }}
                                                checked={appStateContext?.state.isWebBrowsingEnabled}
                                                onChange={(_, checked?: boolean) => { setIsWebBrowsingEnabled(checked ?? false); }}
                                            />
                                        </ThemeProvider>
                                    </div>
                                }
                                {/* {
                                    appStateContext?.state.currentChat?.type != ConversationType.Report
                                    &&
                                    <div>
                                        <Separator />
                                        <div style={{ padding: '0px 25px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                                <div style={{ fontWeight: 600, fontSize: '13px', lineHeight: '18px', color: '#727a90' }}>External data sources:</div>
                                                <IconTooltipInfo />
                                            </div>
                                            <div>
                                                <select style={{ width: '100%', height: '40px', borderRadius: '8px', border: '1px solid rgba(145, 158, 171, 0.2)', padding: '0px 15px' }} disabled>
                                                    <option value="none">None</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                <Separator styles={{ root: { padding: '2px 0px 4px', height: '34px' } }} />
                                {
                                    appStateContext.state.tenantSettings?.visibility.fileUploader
                                    && <div className={styles["sources__file-uploader"]}>
                                        <FileUpload />
                                    </div>
                                }
                            </div>
                        }
                        {
                            appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_CITATIONS_NAME &&
                            <div className={styles["right-pane__tab"]}>
                                <Citation />
                            </div>
                        }
                        {
                            appStateContext?.state.activeRightPaneTab == RIGHT_PANE_TAB_EXPORT_NAME &&
                            <div className={styles["right-pane__tab"]}>
                                <ConversationExport />
                            </div>
                        }
                        <div className={styles["right-pane__end"]}></div>
                    </div>
                }
                <KnowledgeDomainModal setKnowledgeDomain={setKnowledgeDomain} />
            </div>
        </div>
    )
};

export default Layout;