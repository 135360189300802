import styles from "./ChatHistory.module.css";

import { ChatHistoryLoadingState, Conversation, historyList } from "../../api";

import { useContext, useEffect, useRef, useState } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { Spinner, SpinnerSize } from "@fluentui/react";

import ChatHistoryListItem from "./ChatHistoryListItem";

const ChatHistory: React.FunctionComponent = () => {
    const appStateContext = useContext(AppStateContext);
    const chatHistory = appStateContext?.state.chatHistory ?? [];

    const [showSpinner, setShowSpinner] = useState(false);
    const [offset, setOffset] = useState<number>(25);
    const [observerCounter, setObserverCounter] = useState(0);
    const [sortedChatHistory, setSortedChatHistory] = useState<Conversation[]>(chatHistory);
    const firstRender = useRef(true);
    const observerTarget = useRef(null);

    const handleFetchHistory = async () => {
        const currentChatHistory = appStateContext?.state.chatHistory;

        setShowSpinner(true);

        await historyList(offset, appStateContext?.state.currentKnowledgeDomain?.id!)
            .then(
                (response) => {
                    const concatenatedChatHistory = currentChatHistory && response && currentChatHistory.concat(...response);

                    if (response) {
                        appStateContext?.dispatch({ type: "FETCH_CHAT_HISTORY", payload: concatenatedChatHistory || response });
                    } else {
                        appStateContext?.dispatch({ type: "FETCH_CHAT_HISTORY", payload: null });
                    }

                    setShowSpinner(false);

                    return response;
                }
            );
    };

    useEffect(
        () => {
            if (firstRender.current) {
                firstRender.current = false;
                return;
            }

            handleFetchHistory();
            setOffset((offset) => offset += 25);
        },
        [observerCounter]
    );

    useEffect(
        () => {
            const observer = new IntersectionObserver(
                entries => {
                    if (entries[0].isIntersecting) {
                        setObserverCounter((observerCounter) => observerCounter += 1);
                    }
                },
                { threshold: 1 }
            );

            if (observerTarget.current) observer.observe(observerTarget.current);

            return () => {
                if (observerTarget.current) observer.unobserve(observerTarget.current);
            };
        },
        [observerTarget]
    );

    useEffect(
        () => {
            const sortedConversationsList = chatHistory
                .sort(
                    (a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateB.getTime() - dateA.getTime();
                    }
                );
            setSortedChatHistory(sortedConversationsList);
        },
        [appStateContext?.state.chatHistory]
    );

    const renderListContent = () => {
        switch (appStateContext?.state.chatHistoryLoadingState) {
            case ChatHistoryLoadingState.Success:
                return (
                    <div className={styles["chat-history__list"]}>
                        {
                            sortedChatHistory
                                .map(
                                    conversation => (
                                        <ChatHistoryListItem conversation={conversation} />
                                    )
                                )
                        }
                        <div ref={observerTarget} />
                        {
                            showSpinner &&
                            <div className={styles["chat-history__spinner"]}>
                                <Spinner size={SpinnerSize.small} aria-label="loading more chat history" />
                            </div>
                        }
                    </div>
                );
            case ChatHistoryLoadingState.Loading:
                return (
                    <div className={`${styles["chat-history__list"]} ${styles["chat-history__list--loading"]}`}>
                        <Spinner size={SpinnerSize.large} />
                    </div>
                );
            case ChatHistoryLoadingState.Fail:
                return (
                    <div className={`${styles["chat-history__list"]} ${styles["chat-history__list--failed"]}`}>
                        There was an error fetching the session history.
                    </div>
                );
            case ChatHistoryLoadingState.NotStarted:
            default:
                return <></>;
        }
    };

    return (
        <div className={styles["chat-history"]}>
            {renderListContent()}
        </div>
    );
};

export default ChatHistory;