import { useContext, useEffect, useRef } from "react";
import styles from "./Citation.module.css";
import { AppStateContext } from "../../state/AppProvider";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub';
import DOMPurify from 'dompurify';
import { XSSAllowTags } from "../../constants/xssAllowTags";

const Citation = () => {
  const appStateContext = useContext(AppStateContext);
  const citation = appStateContext?.state.activeCitation;

  const citationItemContainerRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (!appStateContext?.state.activeCitation) {
        return;
      }
      citationItemContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
    },
    [appStateContext?.state.activeCitation]
  );

  const getDataPathFromUrl = (url: string): string | null => {
    const lowerUrl = url.toLowerCase();
    const dataIndex = lowerUrl.indexOf("/data");

    if (dataIndex !== -1) {
      // Extract everything from "/data" onward
      const dataPath = url.slice(dataIndex);
      return dataPath
        .replace('-TXT', '-PDF')
        .replace(/%20/g, ' ');
    }

    return null;
  };

  return <div className={styles.citations__container}>
    {!citation
      ? <div className={styles.citations__info}>
        Select a paragraph by clicking
        <i>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18C10.78 18 12.5201 17.4722 14.0001 16.4832C15.4802 15.4943 16.6337 14.0887 17.3149 12.4442C17.9961 10.7996 18.1743 8.99002 17.8271 7.24419C17.4798 5.49836 16.6226 3.89471 15.364 2.63604C14.1053 1.37737 12.5016 0.520203 10.7558 0.172936C9.00998 -0.17433 7.20038 0.00389955 5.55585 0.685088C3.91131 1.36628 2.50571 2.51983 1.51677 3.99987C0.527841 5.47991 0 7.21997 0 9C0.00258081 11.3862 0.951621 13.6738 2.63889 15.3611C4.32616 17.0484 6.61384 17.9974 9 18Z" fill="#C5CAD1" fill-opacity="0.48" />
            <path d="M6.59824 12L6.96824 10.25H5.52824V9.1H7.21824L7.52824 7.64H6.05824V6.49H7.77824L8.14824 4.77H9.34824L8.97824 6.49H10.8782L11.2482 4.77H12.4482L12.0882 6.49H13.3882V7.64H11.8382L11.5282 9.1H12.8582V10.25H11.2782L10.9082 12H9.69824L10.0782 10.25H8.17824L7.80824 12H6.59824ZM8.41824 9.1H10.3182L10.6382 7.64H8.73824L8.41824 9.1Z" fill="#1C252E" />
          </svg>

        </i>
        to see the original source.
      </div>
      : <div className={styles.citations__itemContainer} ref={citationItemContainerRef}>
        <div className={styles.citations__itemContainerHeader}>
          <div className={styles.citations__marker}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0001 23.5714C14.2887 23.5714 16.526 22.8928 18.4289 21.6213C20.3318 20.3498 21.8149 18.5426 22.6907 16.4282C23.5666 14.3138 23.7957 11.9872 23.3492 9.74255C22.9027 7.49791 21.8007 5.43608 20.1824 3.81779C18.5641 2.19949 16.5022 1.09742 14.2576 0.650936C12.013 0.20445 9.68635 0.433603 7.57194 1.30942C5.45754 2.18523 3.65033 3.66837 2.37885 5.57128C1.10736 7.47419 0.428711 9.71141 0.428711 12C0.432029 15.0679 1.65222 18.0092 3.82157 20.1786C5.99091 22.3479 8.93222 23.5681 12.0001 23.5714Z" fill="#1C252E" />
            </svg>
            <span>{citation.reindex_id}</span>
          </div>
          <span>Original Source</span>
        </div>
        <div className={styles.citations__itemContent}>
          {citation.origin !== 'webBrowser' && <>
            <span className={styles.citations__title}>{citation.title?.replace('.txt', '.pdf')}</span>
            <div className={styles.citations__fileLinkButton} onClick={() => { }}>
              <a href={
                appStateContext.state.currentKnowledgeDomain?.sharepointDirectory! +
                (citation.url ? getDataPathFromUrl(citation.url.replace('.md', '.pdf').replace('.txt', '.pdf')) : '')
              }
                target="_blank"
                rel="noopener noreferrer"
              >Open File
                <i>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.49506C9.86739 5.49506 9.74021 5.54773 9.64645 5.6415C9.55268 5.73527 9.5 5.86245 9.5 5.99506V8.99506C9.5 9.12766 9.44732 9.25484 9.35355 9.34861C9.25979 9.44238 9.13261 9.49506 9 9.49506H3C2.86739 9.49506 2.74021 9.44238 2.64645 9.34861C2.55268 9.25484 2.5 9.12766 2.5 8.99506V2.99506C2.5 2.86245 2.55268 2.73527 2.64645 2.6415C2.74021 2.54773 2.86739 2.49506 3 2.49506H6C6.13261 2.49506 6.25979 2.44238 6.35355 2.34861C6.44732 2.25484 6.5 2.12766 6.5 1.99506C6.5 1.86245 6.44732 1.73527 6.35355 1.6415C6.25979 1.54773 6.13261 1.49506 6 1.49506H3C2.60218 1.49506 2.22064 1.65309 1.93934 1.9344C1.65804 2.2157 1.5 2.59723 1.5 2.99506V8.99506C1.5 9.39288 1.65804 9.77441 1.93934 10.0557C2.22064 10.337 2.60218 10.4951 3 10.4951H9C9.39782 10.4951 9.77936 10.337 10.0607 10.0557C10.342 9.77441 10.5 9.39288 10.5 8.99506V5.99506C10.5 5.86245 10.4473 5.73527 10.3536 5.6415C10.2598 5.54773 10.1326 5.49506 10 5.49506Z" fill="#919EAB" />
                    <path d="M8 2.49506H8.79L5.645 5.63506C5.59814 5.68154 5.56094 5.73684 5.53555 5.79777C5.51017 5.8587 5.4971 5.92405 5.4971 5.99006C5.4971 6.05606 5.51017 6.12141 5.53555 6.18234C5.56094 6.24327 5.59814 6.29857 5.645 6.34506C5.69148 6.39192 5.74678 6.42912 5.80771 6.4545C5.86864 6.47989 5.93399 6.49296 6 6.49296C6.06601 6.49296 6.13136 6.47989 6.19229 6.4545C6.25322 6.42912 6.30852 6.39192 6.355 6.34506L9.5 3.20506V3.99506C9.5 4.12766 9.55268 4.25484 9.64645 4.34861C9.74021 4.44238 9.86739 4.49506 10 4.49506C10.1326 4.49506 10.2598 4.44238 10.3536 4.34861C10.4473 4.25484 10.5 4.12766 10.5 3.99506V1.99506C10.5 1.86245 10.4473 1.73527 10.3536 1.6415C10.2598 1.54773 10.1326 1.49506 10 1.49506H8C7.86739 1.49506 7.74021 1.54773 7.64645 1.6415C7.55268 1.73527 7.5 1.86245 7.5 1.99506C7.5 2.12766 7.55268 2.25484 7.64645 2.34861C7.74021 2.44238 7.86739 2.49506 8 2.49506Z" fill="#919EAB" />
                  </svg>
                </i>
              </a>
            </div>
          </>
          }
          {
            citation.origin === 'webBrowser'
              ? <div className={styles.citations__WebSearchLinkButton}>
                <a href={citation.content} target="_blank" rel="noopener noreferrer">{citation.content} <i>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.49506C9.86739 5.49506 9.74021 5.54773 9.64645 5.6415C9.55268 5.73527 9.5 5.86245 9.5 5.99506V8.99506C9.5 9.12766 9.44732 9.25484 9.35355 9.34861C9.25979 9.44238 9.13261 9.49506 9 9.49506H3C2.86739 9.49506 2.74021 9.44238 2.64645 9.34861C2.55268 9.25484 2.5 9.12766 2.5 8.99506V2.99506C2.5 2.86245 2.55268 2.73527 2.64645 2.6415C2.74021 2.54773 2.86739 2.49506 3 2.49506H6C6.13261 2.49506 6.25979 2.44238 6.35355 2.34861C6.44732 2.25484 6.5 2.12766 6.5 1.99506C6.5 1.86245 6.44732 1.73527 6.35355 1.6415C6.25979 1.54773 6.13261 1.49506 6 1.49506H3C2.60218 1.49506 2.22064 1.65309 1.93934 1.9344C1.65804 2.2157 1.5 2.59723 1.5 2.99506V8.99506C1.5 9.39288 1.65804 9.77441 1.93934 10.0557C2.22064 10.337 2.60218 10.4951 3 10.4951H9C9.39782 10.4951 9.77936 10.337 10.0607 10.0557C10.342 9.77441 10.5 9.39288 10.5 8.99506V5.99506C10.5 5.86245 10.4473 5.73527 10.3536 5.6415C10.2598 5.54773 10.1326 5.49506 10 5.49506Z" fill="#919EAB" />
                    <path d="M8 2.49506H8.79L5.645 5.63506C5.59814 5.68154 5.56094 5.73684 5.53555 5.79777C5.51017 5.8587 5.4971 5.92405 5.4971 5.99006C5.4971 6.05606 5.51017 6.12141 5.53555 6.18234C5.56094 6.24327 5.59814 6.29857 5.645 6.34506C5.69148 6.39192 5.74678 6.42912 5.80771 6.4545C5.86864 6.47989 5.93399 6.49296 6 6.49296C6.06601 6.49296 6.13136 6.47989 6.19229 6.4545C6.25322 6.42912 6.30852 6.39192 6.355 6.34506L9.5 3.20506V3.99506C9.5 4.12766 9.55268 4.25484 9.64645 4.34861C9.74021 4.44238 9.86739 4.49506 10 4.49506C10.1326 4.49506 10.2598 4.44238 10.3536 4.34861C10.4473 4.25484 10.5 4.12766 10.5 3.99506V1.99506C10.5 1.86245 10.4473 1.73527 10.3536 1.6415C10.2598 1.54773 10.1326 1.49506 10 1.49506H8C7.86739 1.49506 7.74021 1.54773 7.64645 1.6415C7.55268 1.73527 7.5 1.86245 7.5 1.99506C7.5 2.12766 7.55268 2.25484 7.64645 2.34861C7.74021 2.44238 7.86739 2.49506 8 2.49506Z" fill="#919EAB" />
                  </svg>
                </i></a></div>
              : <div className={styles.citations__caption}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    remarkPlugins={[remarkGfm, supersub]}
                    components={{
                      a(props) {
                        return <a
                          target="_blank"
                          rel="noreferrer"
                          {...props}
                        />
                      },
                      table: ({node, ...props}) => (
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                          <table {...props} />
                        </div>
                      ),
                    }}
                  >
                    {DOMPurify.sanitize(citation.content, { ALLOWED_TAGS: XSSAllowTags })}
                  </ReactMarkdown>
                </div>
          }
        </div>
      </div>
    }
  </div >
}

export default Citation;