import styles from "./QuestionInput.module.css";

import { useContext, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { AppStateContext } from "../../state/AppProvider";

import { useChatStore } from "../../store/Chat.store";

import IconQuestionInputFollowUpChevronUp from "../common/IconQuestionInputFollowUpChevronUp";
import IconQuestionInputClip from "../common/IconQuestionInputClip";

import { Callout, DefaultButton, DirectionalHint } from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";
import { APP_ROUTE_REPORT } from "../../constants/routeNames";

interface QuestionInputProps {
    onSend?: (question: string, id?: string) => void;
    onStopGeneration?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    followUpQuestions?: string[];
    showClipButton?: boolean;
    showSendButton?: boolean;
    textAreaContainerClassName?: string;
    textAreaClassName?: string;
    warningMessageClassName?: string;
}

const QuestionInput: React.FunctionComponent<QuestionInputProps> = (
    {
        onSend,
        onStopGeneration,
        isLoading = false,
        disabled = true,
        placeholder,
        clearOnSend = true,
        conversationId,
        followUpQuestions,
        showClipButton = false,
        showSendButton = true,
        textAreaContainerClassName,
        textAreaClassName,
        warningMessageClassName: warningMessageClasName
    }
) => {
    const { pathname: appLocation } = useLocation();

    const appStateContext = useContext(AppStateContext);
    const userPrompt = useChatStore((state) => state.userPrompt);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const followUpContextMenuRef = useRef(null);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    useLayoutEffect(
        () => {
            if (!textareaRef.current) {
                return;
            }
            textareaRef.current.style.height = 'auto';
            const heightToSet = userPrompt.length > 0 ? textareaRef.current.scrollHeight : 0;
            textareaRef.current.style.height = `${heightToSet}px`;
        },
        [userPrompt]
    );

    const updateUserPrompt = (newUserPrompt: string) => {
        useChatStore.setState({ userPrompt: newUserPrompt });
    };

    const sendQuestion = () => {
        if (!userPrompt.trim() || disabled || !appStateContext?.state.isValidCurrentKnowledgeDomain) {
            return;
        }

        // Track the event using the Fathom global object
        if (window.fathom) {
            window.fathom.trackEvent('research_query_submitted', { eventData: { source: 'send_button' } });
            console.log('tracked');
        }

        if (conversationId) {
            onSend ? onSend(userPrompt, conversationId) : null;
        } else {
            onSend ? onSend(userPrompt) : null;
        }

        if (clearOnSend) {
            useChatStore.setState({ userPrompt: "" });
        }

        const openPromptButton = document.getElementById('openDialogButtonTopic');
        openPromptButton?.style.setProperty('display', 'block');
    };

    const stopGeneration = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (onStopGeneration) {
            onStopGeneration();
        }
    }

    const onEnterPress = (event: React.KeyboardEvent<Element>) => {
        if (event.key === "Enter" && !event.shiftKey && !(event.nativeEvent?.isComposing === true)) {
            event.preventDefault();
            if (onSend) {
                sendQuestion();
            }
        }
    };

    const openRightPane = () => {
        appStateContext?.dispatch({ type: "TOGGLE_RIGHT_PANE", payload: true });
    };

    return (
        <div className={styles["question-input__wrapper"]}>
            <div
                className={`${styles.questionInput__container} ${appLocation == APP_ROUTE_REPORT ? styles["questionInput__container--report"] : ""} ${appLocation == APP_ROUTE_REPORT && !disabled ? styles["questionInput__container--report--enabled"] : ""}`}
                ref={followUpContextMenuRef}
            >
                {
                    followUpQuestions && followUpQuestions.length > 0
                    && <>
                        <div style={{ display: "flex", alignItems: "center", alignSelf: "end", height: "32px" }}>
                            <DefaultButton
                                styles={{
                                    root: { minWidth: "110px", height: "30px", padding: "0px 5px", borderRadius: "20px", backgroundColor: "rgba(145, 158, 171, 0.24)", border: "none" },
                                    flexContainer: { gap: "5px", flexDirection: 'row-reverse' },
                                    textContainer: { flexGrow: "0" },
                                    label: { fontWeight: 400, fontSize: "12px", lineHeight: "18px", color: "#1c252e", margin: "0px" }
                                }}
                                text={`${followUpQuestions.length} follow-ups`}
                                onRenderIcon={() => { return (<IconQuestionInputFollowUpChevronUp />); }}
                                onClick={toggleIsCalloutVisible}
                            />
                        </div>
                        {
                            isCalloutVisible &&
                            <Callout
                                className={styles["question-input__contextual-menu"]}
                                target={followUpContextMenuRef}
                                isBeakVisible={false}
                                gapSpace={10}
                                onDismiss={toggleIsCalloutVisible}
                                directionalHintFixed={true}
                                directionalHint={DirectionalHint.topLeftEdge}
                                alignTargetEdge={true}
                                styles={{ calloutMain: { padding: "4px", borderRadius: "12px", display: "flex", flexDirection: "column", gap: "4px" } }}
                            >
                                {
                                    followUpQuestions.map(question =>
                                        <div
                                            className={styles["question-input__contextual-menu-item"]}
                                            onClick={() => { toggleIsCalloutVisible(); updateUserPrompt(question); }}
                                        >
                                            {question}
                                        </div>
                                    )
                                }
                            </Callout>
                        }
                    </>
                }
                <div className={`${styles.questionInput__textAreaContainer} ${textAreaContainerClassName ? textAreaContainerClassName : ""}`}>
                    <textarea
                        className={`${styles.questionInput__textArea} ${textAreaClassName ? textAreaClassName : ""}`}
                        placeholder={placeholder}
                        aria-invalid="false"
                        value={userPrompt}
                        ref={textareaRef}
                        onChange={(e) => updateUserPrompt(e.target.value)}
                        rows={1}
                        onKeyDown={onEnterPress}
                        disabled={disabled || !appStateContext?.state.isValidCurrentKnowledgeDomain}
                    ></textarea>
                </div>

                {
                    showClipButton
                    && <div
                        className={styles["questionInput__clipButtonContainer"]}
                        onClick={openRightPane}
                    >
                        <IconQuestionInputClip />
                    </div>
                }
                <div
                    className={`${styles.questionInput__sendButtonContainer} ${!showSendButton ? styles["questionInput__sendButtonContainer--hidden"] : ""}`}
                    role="button"
                    tabIndex={0}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                    onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                >
                    {
                        isLoading ?
                            <i onClick={stopGeneration}>
                                <svg className={styles.questionInput__stopGenerationButton} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" height="40" width="40" version="1.1" viewBox="0 0 40 40" xmlSpace="preserve">
                                    <path id="XMLID_150_" d="M19.994,0C8.952,0,0,8.952,0,19.995c0,11.043,8.952,19.994,19.994,19.994  c11.043,0,19.995-8.952,19.995-19.994C39.989,8.952,31.037,0,19.994,0z M27.744,24.526c0,1.778-1.441,3.219-3.219,3.219h-9.063  c-1.778,0-3.219-1.441-3.219-3.219v-9.063c0-1.778,1.441-3.219,3.219-3.219h9.063c1.778,0,3.219,1.441,3.219,3.219V24.526z" />
                                </svg>
                            </i>
                            :
                            <svg className={styles.questionInput__sendButton} width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#1C252E" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.402 14.6631C11.142 12.3291 13.545 10.6151 15.668 11.6211L27.612 17.2791C29.9 18.3621 29.9 21.6181 27.612 22.7011L15.668 28.3601C13.545 29.3661 11.143 27.6521 11.402 25.3181L11.882 20.9901H20C20.2652 20.9901 20.5195 20.8848 20.7071 20.6972C20.8946 20.5097 21 20.2553 21 19.9901C21 19.7249 20.8946 19.4705 20.7071 19.283C20.5195 19.0955 20.2652 18.9901 20 18.9901H11.883L11.403 14.6631H11.402Z" fill="white" />
                            </svg>
                    }
                </div>
            </div>
            <div className={`${styles["question-input__message-ai-warning"]} ${warningMessageClasName ? warningMessageClasName : ""}`}>
                Madison AI can make mistakes. Check important information.
            </div>
        </div>
    );
};

export default QuestionInput;