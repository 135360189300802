import styles from "./ConversationExport.module.css";

import { useContext } from "react";

import { AppStateContext } from "../../state/AppProvider";

import { ConversationType, exportConversation, ExportFileFormat } from "../../api";

import IconExportConversationToPDF from "../common/IconExportConversationToPDF";
import IconExportConversationToDOCX from "../common/IconExportConversationToDOCX";
import IconExportConversationToTXT from "../common/IconExportConversationToTXT";

import { DefaultButton, IButtonStyles } from "@fluentui/react";

const ConversationExport: React.FunctionComponent = () => {
    const appStateContext = useContext(AppStateContext);

    const exportToFile = async (format: ExportFileFormat) => {
        const currentConversation = appStateContext?.state.currentChat;

        if (!currentConversation) {
            return;
        }

        try {
            const response = await exportConversation(format, currentConversation);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}. Message: ${response.statusText}`);
            }

            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `madisonai_${currentConversation.type}_${currentConversation.id}.${format}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const buttonStyles: Partial<IButtonStyles> = {
        root: { height: "36px", borderRadius: "8px", border: "1px solid rgba(145, 158, 171, 0.32)" },
        flexContainer: { gridGap: "8px" },
        textContainer: { flexGrow: "initial" },
        label: { fontWeight: 700, fontSize: "14px", lineHeight: "24px", textAlign: "center", color: "#1c252e" }
    };

    return (
        <div className={styles["chat-export"]}>
            <div className={styles["chat-export__tooltip"]}>
                <div className={styles["chat-export__tooltip-text"]}>Your export will include the entire exchange from this session.</div>
            </div>
            <div className={styles["chat-export__content"]}>
                <DefaultButton text="Export as .pdf" styles={buttonStyles} onRenderIcon={() => { return (<IconExportConversationToPDF />); }} onClick={() => exportToFile(ExportFileFormat.PDF)} />
                <DefaultButton text="Export as .docx" styles={buttonStyles} onRenderIcon={() => { return (<IconExportConversationToDOCX />); }} onClick={() => exportToFile(ExportFileFormat.DOCX)} />
                {
                    appStateContext?.state.currentChat?.type == ConversationType.Report
                    && <DefaultButton text="Export as plain text" styles={buttonStyles} onRenderIcon={() => { return (<IconExportConversationToTXT />); }} onClick={() => exportToFile(ExportFileFormat.TXT)} />
                }
            </div>
        </div>
    );
};

export default ConversationExport;